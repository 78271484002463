import React from "react";
import STHomepage from "../PureComponents/STHomepage";

const RenderSTHomepage = () => {
  return (
    <>
      <STHomepage />
    </>
  );
};

export default RenderSTHomepage;
