import "./STHomepage.css";

import React, { useEffect, useRef, useState } from "react";
import {
  notConnectedAmniusAction,
  raiseRequestDataAction,
  selectedOrganizationSTAction,
} from "Redux/action/variable.action";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import RenderConfirm from "Components/Popup/Option/Container/RenderConfirm";
import SearchIcon from "@mui/icons-material/Search";
import blank from "Media/blank-profile-picture.png";
import { listOfAppointOrg } from "Config/API/get";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const STHomepage = () => {
  const token = localStorage.getItem("token");
  const [connectedOrgs, setConnectedOrgs] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmPopup, setConfirmPopup] = useState(false);

  const ticket_role = useSelector(
    (state) => state.authenticationReducer?.ticket_role
  );

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          ref.current.classList.add("hide-dropdown");
        } else {
          if (ref.current !== null) {
            ref.current.classList.remove("hide-dropdown");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  // connected or not function
  const connectedFunction = (option) => {
    if (option?.toString() === "Yes") {
      dispatch(notConnectedAmniusAction(false));
      navigate("/service-ticket-create");
      dispatch(selectedOrganizationSTAction(""));
      dispatch(raiseRequestDataAction(""));
    } else {
      dispatch(notConnectedAmniusAction(true));
      navigate("/service-ticket-create");
      dispatch(selectedOrganizationSTAction(""));
      dispatch(raiseRequestDataAction(""));
    }
  };

  useEffect(() => {
    if (ticket_role?.toString() !== "manager") {
      navigate("/service-ticket-dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="stphome-parent">
        <div className="stphome-header">
          <Headers />
        </div>

        <div className="stphome-navigation">
          <Navigation />
        </div>

        <div className="stphome-main">
          <div className="stphome-heading">
            <p>Service Ticketing Homepage</p>
          </div>
          <div className="stphome-ticket-search">
            <div
              className="stphome-ticket-wrapper"
              ref={wrapperRef}
              onClick={() => {
                dispatch(notConnectedAmniusAction(false));
                const orgs_status = listOfAppointOrg(token, "");
                orgs_status.then((res) => {
                  if (res?.status) {
                    setConnectedOrgs(res?.organizationData);
                  } else {
                    toast(res?.message, {
                      type: "error",
                    });
                  }
                });
              }}
              onChange={(e) => {
                const orgs_status = listOfAppointOrg(token, e.target.value);
                orgs_status.then((res) => {
                  if (res?.status) {
                    setConnectedOrgs(res?.organizationData);
                  } else {
                    toast(res?.message, {
                      type: "error",
                    });
                  }
                });
              }}
            >
              <input type="search" placeholder="Search connections" />
              <SearchIcon />

              {/*****************search result*********************/}
              <div className="organization-list">
                {connectedOrgs?.length?.toString() !== "0" ? (
                  !connectedOrgs?.message ? (
                    connectedOrgs?.map((curr, index) => {
                      return (
                        <div className="data-list" key={index}>
                          <div>
                            <img
                              src={curr?.logo ? curr?.logo : blank}
                              alt="Logo"
                            />
                            <span>
                              {curr?.organization_name
                                ? curr?.organization_name
                                : "No Name"}
                            </span>
                          </div>
                          <Button
                            type="button"
                            variant="contained"
                            onClick={() => {
                              setConnectedOrgs("");
                              dispatch(
                                selectedOrganizationSTAction([
                                  curr?.id,
                                  curr?.organization_name,
                                ])
                              );
                              dispatch(notConnectedAmniusAction(false));
                              navigate("/service-ticket-create");
                            }}
                          >
                            Create Ticket
                          </Button>
                        </div>
                      );
                    })
                  ) : (
                    <div className="orgListAlert">
                      No Such Connected Organizaton Found
                    </div>
                  )
                ) : null}
              </div>
            </div>
          </div>
          <div className="stphome-options-btn">
            <button
              onClick={() => {
                setConfirmPopup(true);
              }}
            >
              Create New Ticket
            </button>

            {confirmPopup ? (
              <RenderConfirm
                message={"Is Requestor A Connected Amnius User?"}
                option1={"Yes"}
                option2={"No"}
                funct={connectedFunction}
                hide={setConfirmPopup}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default STHomepage;
