import { getMessaging } from "firebase/messaging";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAvccGlSfJnEAgzn9CmOeCnZrhJTyh9vbA",
  authDomain: "amnius-connect.firebaseapp.com",
  projectId: "amnius-connect",
  storageBucket: "amnius-connect.appspot.com",
  messagingSenderId: "276009679670",
  appId: "1:276009679670:web:d14332ae75fc14dacf9197",
  measurementId: "G-KQHZFZQN7E",
};

export const initializeFirebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(initializeFirebaseApp);
