import "../STPremiumPage/PureComponents/STPremiumPage.css";

import React, { useEffect, useState } from "react";
import {
  getAllInternalTickets,
  getCategoryData,
  getInternalTicketList,
  getLocationData,
  getPersonalizedTicketList,
  getRequestorList,
} from "Config/API/get";
import {
  notConnectedAmniusAction,
  raiseRequestDataAction,
  selectedOrganizationSTAction,
} from "Redux/action/variable.action";
import { useDispatch, useSelector } from "react-redux";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Headers from "Components/Header/Headers";
import Low from "Media/icons/Low.png";
import Moderate from "Media/icons/Moderate.png";
import Navigation from "Components/SideNavBar/Navigation";
import Pause from "Media/icons/Pause.png";
import RenderConfirm from "Components/Popup/Option/Container/RenderConfirm";
import RequestTypePopup from "Components/Popup/RequestTypePopup/PureComponents/RequestTypePopup";
import SearchIcon from "@mui/icons-material/Search";
import Urgent from "Media/icons/Urgent.png";
import moment from "moment-timezone";
import { ticketIdAction } from "Redux/action/variable.action";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useNavigate } from "react-router-dom";

const AllInternalTickets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let doneTypingInterval = 1500;
  let typingTimer;
  const pageType = useSelector((state) => state.servicePageTypeReducer);
  const general_info = useSelector((state) => state.authenticationReducer);
  const token = localStorage.getItem("token");
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const [ticketList, setTicketList] = useState([]);
  const [filterValue, setFilterValue] = useState("all");
  const [personalizedTicketList, setPersonalizedTicketList] = useState("");
  const [personalizedFilterKeyword, setPersonalizedFilterKeyword] =
    useState("");
  const [searchKeyWords, setSearchKeywords] = useState("");
  const [location_list, setLocation_list] = useState([]);
  const [locationName, setLocationName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [teams, setTeams] = useState([]);
  const [filterKeyword, setFilterKeyword] = useState("");
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [requestTypeconfirmPopup, setRequestTypeConfirmPopup] = useState(false);
  const loggedInUser = useSelector((state) => state.authenticationReducer);
  useEffect(() => {
    getAllLocations();
    personalized_filter_function("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllInternalServiceTickets(
      filterValue,
      searchKeyWords,
      locationName,
      categoryName
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, searchKeyWords]);

  useEffect(() => {
    const categoryList = getCategoryData(token, general_info?.id, locationName);
    categoryList.then((res) => {
      setTeams(res?.category);
    });
  }, [locationName]);
  const getAllLocations = () => {
    const locationList = getLocationData(token, general_info?.id);
    locationList.then((res) => {
      if (res?.location?.length > 0) {
        setLocation_list(res?.location);
      }
    });
  };
  const getAllInternalServiceTickets = (
    filter,
    searchKeyword,
    location,
    categoryId
  ) => {
    setLoader();
    const response = getAllInternalTickets(
      token,
      filter,
      searchKeyword,
      location,
      categoryId
    );
    response.then((res) => {
      resetLoader();
      if (res?.data?.status) {
        setTicketList(res?.data?.ticket);
      } else toast(res?.data?.message, { type: "error" });
    });
  };
  const personalized_filter_function = (value) => {
    setLoader();
    const personalized_ticket_status = getPersonalizedTicketList(
      token,
      value,
      "",
      "internal"
    );
    personalized_ticket_status.then((res) => {
      resetLoader();
      if (res?.status) {
        setPersonalizedTicketList(res?.ticket);
      } else toast(res?.message, { type: "error" });
    });
  };

  const search_fuction = (value) => {
    if (pageType?.toString() === "PREMIUM") {
      const ticket_status = getInternalTicketList(
        token,
        filterKeyword,
        value,
        "",
        ""
      );
      ticket_status.then((res) => {
        if (res?.status) {
          setTicketList(res?.ticket);
        } else
          toast(res?.message, {
            type: "error",
          });
      });

      const personalized_ticket_status = getPersonalizedTicketList(
        token,
        personalizedFilterKeyword,
        value,
        "internal"
      );
      personalized_ticket_status.then((res) => {
        if (res?.status) {
          setPersonalizedTicketList(res?.ticket);
        }
      });
    } else {
      const ticket_status = getRequestorList(token, filterKeyword, value);
      ticket_status.then((res) => {
        if (res?.status) {
          setTicketList(res?.ticket);
        }
      });
    }
  };
  // creating tickets
  const requestTypeConnectedFunction = (option) => {
    if (option === "Internal")
      navigate("/create-internal-service-ticket", {
        state: "new ticket",
      });
    else setConfirmPopup(true);
    setRequestTypeConfirmPopup(false);
  };

  // connected or not function
  const connectedFunction = (option) => {
    if (option?.toString() === "Yes") {
      dispatch(notConnectedAmniusAction(false));
      navigate("/service-ticket-create", {
        state: "new ticket",
      });
      dispatch(selectedOrganizationSTAction(""));
      dispatch(raiseRequestDataAction(""));
    } else {
      dispatch(notConnectedAmniusAction(true));
      navigate("/service-ticket-create", {
        state: "new ticket",
      });
      dispatch(selectedOrganizationSTAction(""));
      dispatch(raiseRequestDataAction(""));
    }
  };
  return (
    <>
      <div className="stpmain-parent">
        <div className="stpmain-header">
          <Headers />
        </div>

        <div className="stpmain-navigation">
          <Navigation />
        </div>

        <div className="stpmain-main">
          <div className="stpmain-ticket-search">
            <input
              type="search"
              placeholder={
                pageType?.toString() === "PREMIUM"
                  ? "Search tickets by ticket # or requestor name"
                  : "Search tickets by ticket # or provider name"
              }
              onChange={(e) => {
                typingTimer = setTimeout(() => {
                  search_fuction(e.target.value);
                  setSearchKeywords(e.target.value);
                }, doneTypingInterval);
              }}
            />
            <SearchIcon />
          </div>
          {pageType?.toString() === "PREMIUM" &&
          loggedInUser?.ticket_read_role === "manager" ? (
            <div className="stpmain-options-btn">
              <button
                className={
                  loggedInUser?.ticket_read_role !== "manager"
                    ? "external-rights"
                    : "stpmain-outline-btn"
                }
                onClick={() => {
                  if (loggedInUser?.ticket_read_role === "manager") {
                    navigate("/service-ticket-view-page");
                  }
                }}
              >
                EXTERNAL TICKETS
              </button>
              <button
                disabled={loggedInUser?.ticket_role !== "manager"}
                className={
                  loggedInUser?.ticket_role !== "manager"
                    ? "external-rights"
                    : "create-ticket"
                }
                onClick={() => {
                  setRequestTypeConfirmPopup(true);
                }}
              >
                Create New Ticket
              </button>
              <button
                className={"stpmain-outline-btn-active"}
                onClick={() => {
                  navigate("/all-internal-service-tickets");
                }}
              >
                INTERNAL TICKETS
              </button>
            </div>
          ) : null}
          <div className="stpmain-wrapper">
            <div className="stpmain-all-active" id="ticket-body">
              <div className="stpmain-top">
                <p>All Active Service Tickets</p>
                <div>
                  <p>Location:</p>
                  <select
                    onChange={(e) => {
                      getAllInternalServiceTickets("", "", e.target.value, "");
                      setLocationName(e.target.value);
                    }}
                  >
                    <option value="all">All</option>
                    {location_list?.map((item) => (
                      <option
                        key={item?.key}
                        label={item?.location}
                        value={item?.id}
                      >
                        {item?.location}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <p>Team:</p>
                  <select
                    onChange={(e) => {
                      getAllInternalServiceTickets(
                        "",
                        "",
                        locationName,
                        e.target.value
                      );
                      setCategoryName(e.target.value);
                    }}
                    disabled={locationName === ""}
                  >
                    <option value="all">All</option>
                    {teams?.length > 0 && (
                      <>
                        {teams?.map((item) => (
                          <option key={item?.key} value={item?.id}>
                            {item?.category_id}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                </div>
                <div>
                  <p>Filter:</p>
                  <select onChange={(e) => setFilterValue(e.target.value)}>
                    <option value="all">All</option>
                    <option value="urgent">Urgent</option>
                    <option value="moderate">Moderate</option>
                    <option value="low">Low</option>
                    <option value="pause">Paused</option>
                    <option value="closed">Closed</option>
                  </select>
                </div>
              </div>
              <div className="stpmain-row-wrapper">
                <div className="stpmain-head-row" style={{ width: "30%" }}>
                  TICKET #
                </div>
                <div className="stpmain-head-row">REQUESTOR</div>
                <div className="stpmain-head-row">ASSIGNED TECH</div>
                <div className="stpmain-head-row">LOCATION</div>
                <div className="stpmain-head-row">TEAM</div>
                <div className="stpmain-head-row">SEVERITY</div>
                <div className="stpmain-head-row">DATE OPENED</div>
              </div>
              <div className="stpmain-bottom">
                {ticketList?.length > 0 ? (
                  ticketList?.map((item, index) => {
                    return (
                      <div className="stpmain-content-wrapper" key={index}>
                        <div
                          className="stpmain-content-row"
                          style={{ width: "30%" }}
                          onClick={() => {
                            dispatch(ticketIdAction(item?.ticket_id));
                            navigate("/service-ticket-details");
                          }}
                        >
                          {item?.ticket_id}
                        </div>
                        <div className="stpmain-content-row">
                          {item?.requestor_name}
                        </div>
                        <div className="stpmain-content-row">
                          {item?.assign_tech
                            ? item?.assign_tech
                            : "Not Assigned"}
                        </div>
                        <div className="stpmain-content-row">
                          {item?.locationName}
                        </div>
                        <div className="stpmain-content-row">
                          {item?.categoryName}
                        </div>
                        <div className="stpmain-content-row">
                          {item?.severity_issue?.toString() === "urgent" ? (
                            <>
                              <img src={Urgent} alt="Urgent" /> Urgent
                            </>
                          ) : item?.severity_issue?.toString() ===
                            "moderate" ? (
                            <>
                              <img src={Moderate} alt="Moderate" /> Moderate
                            </>
                          ) : item?.severity_issue?.toString() === "low" ? (
                            <>
                              <img src={Low} alt="Low" /> Low
                            </>
                          ) : item?.severity_issue?.toString() === "pause" ? (
                            <>
                              <img src={Pause} alt="Pause" /> Paused
                            </>
                          ) : item?.severity_issue?.toString() === "closed" ? (
                            <>
                              <span
                                style={{
                                  backgroundColor: "orange",
                                  borderRadius: "2px",
                                  textAlign: "center",
                                  width: "20px",
                                  height: "18px",
                                  color: "white",
                                  marginRight: "5px",
                                }}
                              >
                                C
                              </span>
                              Closed
                            </>
                          ) : null}
                        </div>
                        <div
                          className="stpmain-content-row stpmain-date-row"
                          style={{ position: "relative" }}
                        >
                          {moment(item?.createdAt).format("MM/DD/YYYY")}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="stpmain-msg">No Tickets Found</div>
                )}
              </div>
            </div>
            {pageType?.toString() === "PREMIUM" ? (
              <div className="stpmain-my-active">
                <div className="stpmain-top">
                  <p>My Active Service Tickets</p>
                  <div>
                    <p>Filter:</p>
                    <select
                      onChange={(e) => {
                        personalized_filter_function(e.target.value);
                        setPersonalizedFilterKeyword(e.target.value);
                      }}
                    >
                      <option value="all">All</option>
                      <option value="urgent">Urgent</option>
                      <option value="moderate">Moderate</option>
                      <option value="low">Low</option>
                      <option value="pause">Pause</option>
                      <option value="closed">Closed</option>
                    </select>
                  </div>
                </div>
                <div className="stpmain-row-wrapper stpmain-right-side">
                  <div className="stpmain-head-row" style={{ width: "25%" }}>
                    TICKET #
                  </div>
                  <div className="stpmain-head-row">REQUESTOR</div>
                  <div className="stpmain-head-row">SEVERITY</div>
                  <div className="stpmain-head-row">DATE OPENED</div>
                </div>
                <div className="stpmain-bottom stpmain-right-side">
                  {personalizedTicketList?.length?.toString() !== "0" ? (
                    personalizedTicketList?.map((curr, index) => {
                      return (
                        <div className="stpmain-content-wrapper" key={index}>
                          <div
                            className="stpmain-content-row"
                            style={{ width: "25%" }}
                            onClick={() => {
                              dispatch(ticketIdAction(curr?.ticket_id));
                              navigate("/service-ticket-details");
                            }}
                          >
                            {curr?.ticket_id}
                          </div>
                          <div className="stpmain-content-row">
                            {curr?.requestor}
                          </div>
                          <div className="stpmain-content-row">
                            {curr?.severity_issue?.toString() === "urgent" ? (
                              <>
                                <img src={Urgent} alt="Urgent" /> Urgent
                              </>
                            ) : curr?.severity_issue?.toString() ===
                              "moderate" ? (
                              <>
                                <img src={Moderate} alt="Moderate" /> Moderate
                              </>
                            ) : curr?.severity_issue?.toString() === "low" ? (
                              <>
                                <img src={Low} alt="Low" /> Low
                              </>
                            ) : curr?.severity_issue?.toString() === "pause" ? (
                              <>
                                <img src={Pause} alt="Pause" /> Pause
                              </>
                            ) : curr?.severity_issue?.toString() ===
                              "closed" ? (
                              <>
                                <span
                                  style={{
                                    backgroundColor: "orange",
                                    borderRadius: "2px",
                                    textAlign: "center",
                                    width: "20px",
                                    height: "18px",
                                    color: "white",
                                    marginRight: "5px",
                                  }}
                                >
                                  C
                                </span>
                                Closed
                              </>
                            ) : null}
                          </div>
                          <div
                            className="stpmain-content-row stpmain-date-row"
                            style={{ position: "relative" }}
                          >
                            {curr?.create_at}

                            {curr?.scheduled_status?.toString() !== "no" ? (
                              curr?.scheduled_status?.toString() === "green" ? (
                                <AccessTimeIcon style={{ color: "#5ca127" }} />
                              ) : (
                                <AccessTimeIcon style={{ color: "#d81c1c" }} />
                              )
                            ) : null}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="stpmain-msg">No Tickets Found</div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
          {requestTypeconfirmPopup ? (
            <RequestTypePopup
              message={"Please Select Request Type"}
              option1={"External"}
              option2={"Internal"}
              funct={requestTypeConnectedFunction}
              hide={setRequestTypeConfirmPopup}
            />
          ) : null}
          {confirmPopup ? (
            <RenderConfirm
              message={"Is Requestor A Connected Amnius User?"}
              option1={"Yes"}
              option2={"No"}
              funct={connectedFunction}
              hide={setConfirmPopup}
            />
          ) : null}
          {loader}
        </div>
      </div>
    </>
  );
};

export default AllInternalTickets;
