import "./STDetails.css";

import { Button, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  addingMultipleFilesST,
  additionalNotesApi,
  closeServiceTicket,
  deletingTicketFiles,
  reassignTechApi,
  updateSeverityApi,
} from "Config/API/action";
import {
  getExternalMembers,
  getInternalFolder,
  getInternalMembers,
  getUserList,
  serviceTicketDetails,
} from "Config/API/get";
import {
  internalFolderDataAction,
  selectedFolderDataAction,
  selectedFolderRootDataAction,
} from "Redux/action/get.action";
import { maxFileLength, textAreaLength } from "Constants/MaxFileLength";
import {
  renderChatProfileAction,
  ticketChatDataAction,
} from "Redux/action/render.action";
import { useDispatch, useSelector } from "react-redux";

import ChatIcon from "@mui/icons-material/Chat";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FolderLinkIcon from "Media/FolderLink.png";
import Headers from "Components/Header/Headers";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import IosShareIcon from "@mui/icons-material/IosShare";
import LanguageIcon from "@mui/icons-material/Language";
import { Link } from "react-router-dom";
import Navigation from "Components/SideNavBar/Navigation";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import RenderConfirm from "Components/Popup/Confirm/PureComponents/Confirm";
import RenderCreateExternalChatRoom from "Components/Popup/CreateExternalChatRoom/Container/RenderCreateExternalChatRoom";
import RenderCreateInternalChatRoom from "Components/Popup/CreateInternalChatRoom/Container/RenderCreateInternalChatRoom";
import RenderEmail from "Components/Popup/Email/Container/RenderEmail";
import STAppointmentPopup from "Components/Popup/STAppointmentPopup/PureComponents/STAppointmentPopup";
import STReassignPopup from "Components/Popup/STReassignPopup/PureComponents/STReassignPopup";
import StorageLimitReachedPopup from "Components/Popup/StorageLimitReachedPopup/StorageLimitReachedPopup";
import TextsmsIcon from "@mui/icons-material/Textsms";
import VideoIcon from "Media/icons/Iconly-Bold-Video.svg";
import closeIcon from "Media/icons/Close Ticket@2x.png";
import { currentFolderAutomationAction } from "Redux/action/variable.action";
import excelIcon from "Media/icons/simple-microsoftexcel.svg";
import imageIcon from "Media/icons/simple-image.png";
import inspectionIcon from "Media/icons/inspection (1)@2x.png";
import officeIcon from "Media/icons/simple-microsoftword.svg";
import pauseIcon from "Media/icons/pause@2x.png";
import pdfIcon from "Media/icons/simple-pdf.svg";
import profilePic from "Media/blank-profile-picture.png";
import reassignIcon from "Media/icons/Reassign Ticket@2x.png";
import { toast } from "react-toastify";
import unknownFile from "Media/icons/unknownFile.png";
import useFullPageLoader from "Hooks/useFullPageLoader";
import useIsStorageLimitReached from "Hooks/useIsStorageLimitReached";
import { useNavigate } from "react-router-dom";
import videoFile from "Media/icons/videoFile.png";

const STDetails = () => {
  const isStorageLimitReached = useIsStorageLimitReached();
  const selectedTicketId = useSelector((state) => state.ticketIdReducer);
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const current_org_id = useSelector((state) => state.renderGeneralInfo?.id);
  const my_id = useSelector((state) => state.authenticationReducer?.id);
  let my_org_id = useSelector((state) => state.authenticationReducer?.org_id);
  const user = useSelector((state) => state.authenticationReducer);
  const loggedInUserDetails = useSelector(
    (state) => state.authenticationReducer
  );
  const [group_data, setGroup_data] = useState([]);
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [fileName, setFileName] = useState("");
  // condition for admin
  if (my_org_id?.toString() === "0") {
    my_org_id = my_id;
  }

  // refs **
  const wrapperRef = useRef(null);
  const selectRef = useRef(null);

  useOutsideAlerter(wrapperRef);
  useOutsideAlerter(selectRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          ref.current.classList.add("hide-dropdown");
        } else {
          if (ref.current !== null) {
            ref.current.classList.remove("hide-dropdown");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  // details
  const [ticketId, setTicketId] = useState("");
  const [severity, setSeverity] = useState("none");
  const [folder, setFolder] = useState("");
  const [folderLink, setFolderLink] = useState("");
  const [machineNo, setMachineNo] = useState("");
  const [desc, setDesc] = useState("");
  const [create_at, setCreate_at] = useState("");
  const [files, setFiles] = useState("");
  const [ticket_org_id, setTicket_org_id] = useState("");
  const [ticketStatus, setTicketStatus] = useState("None");
  const [serviceTicketType, setServiceTicketType] = useState("");
  const [notes, setNotes] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [notesCharCounter, setNotesCharCounter] = useState(1000);
  const [notesDefualtChar, setNotesDefualtChar] = useState(0);

  // requestor company
  const [requestorLogo, setRequestorLogo] = useState(profilePic);
  const [requestor, setRequestor] = useState("Not Found");
  const [requestorId, setRequestorId] = useState("Not Found");
  const [requestorWeb, setRequestorWeb] = useState("Not Found");
  const [requestorAddress, setRequestorAddress] = useState("Not Found");

  // assigned tech
  const [assignId, setAssignId] = useState("");
  const [assignLogo, setAssignLogo] = useState(profilePic);
  const [activeStatus, setActiveStatus] = useState("");
  const [assignName, setAssignName] = useState("No Username Found");
  const [assignDes, setAssignDes] = useState("No Designation Found");
  const [assignEmail, setAssignEmail] = useState("No Email Found");
  const [assignPhone, setAssignPhone] = useState("No Phone Found");
  const [assignCompLogo, setAssignCompLogo] = useState(profilePic);
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  // requestor tech
  const [requesId, setRequesId] = useState("");
  const [requesLogo, setRequesLogo] = useState(profilePic);
  const [requesStatus, setRequesStatus] = useState("");
  const [requesName, setRequesName] = useState("No Username Found");
  const [requesDes, setRequesDes] = useState("No Designation Found");
  const [requesEmail, setRequesEmail] = useState("No Email Found");
  const [requesPhone, setRequesPhone] = useState("No Phone Found");
  const [requestorOrgId, setRequestorOrgId] = useState("");

  // triggers
  const [EmailPopup, setEmailPopup] = useState(false);
  const [AssignEmailPopup, setAssignEmailPopup] = useState(false);
  const [dropUp, setDropUp] = useState(false);
  const [openTicketSelect, setOpenTicketSelect] = useState(false);
  const [appointmentPopup, setAppointmentPopup] = useState(false);
  const [reassignPopup, setReassignPopup] = useState(false);
  const [closePopup, setClosePopup] = useState(false);
  const [ticketChatData, setTicketChatData] = useState("");
  const [doesExternalExist, setDoesExternalExist] = useState(false);

  // video chat
  // eslint-disable-next-line no-unused-vars
  const [loggedInUser, setLoggedInUser] = useState([]);
  const [openInternalChat, setOpenInternalChat] = useState(false);
  const [openExternalChat, setOpenExternalChat] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState([]);
  const [availableMember, setAvailableMember] = useState([]);
  const [externalMember, setExternalMember] = useState([]);
  const [allExternalMember, setAllExternalMember] = useState([]);
  const [videoCallCreatorAdmin, setVideoCallCreatorAdmin] = useState([]);
  // to show popups
  const [showStorageLimitReachedPopup, setShowStorageLimitReachedPopup] =
    useState(false);
  //getting service ticket details
  useEffect(() => {
    getServiceTicketDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loggedInUser?.includes(user)) {
      loggedInUser?.push(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
    getExternalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestorOrgId]);

  useEffect(() => {
    if (!openExternalChat) {
      setSelectedTeamMember([]);
      setExternalMember([]);
    }
  }, [openExternalChat]);

  const getServiceTicketDetails = () => {
    setLoader();
    const ticket_data = serviceTicketDetails(token, selectedTicketId);
    ticket_data.then((res) => {
      resetLoader();
      if (res?.status) {
        //ticket chat data
        setTicketChatData(res?.ticket[0]);
        // external chat
        setDoesExternalExist(res?.ticket[0]?.externalGroup);
        // regular data
        setTicketId(res?.ticket[0]?.ticket_id || "Not Found");
        setSeverity(res?.ticket[0]?.severity_issue || "none");
        setFolder(res?.ticket[0]?.connect_folder_id || "Not Found");
        setFolderLink(res?.ticket[0]?.connect_folder || false);
        setMachineNo(res?.ticket[0]?.machine_serial || "Not Found");
        setDesc(res?.ticket[0]?.description || "Not Found");
        setCreate_at(res?.ticket[0]?.create_at || "Not Found");
        setFiles(res?.ticket[0]?.files);
        setTicket_org_id(res?.ticket[0]?.org_id || "Not Found");
        setTicketStatus(res?.ticket[0]?.status || "None");
        setNotes(res?.ticket[0]?.additional_notes || "");
        setServiceTicketType(res?.ticket[0]?.serviceticket_type);
        setCategoryId(res?.ticket[0]?.category_id);
        setCategoryName(res?.ticket[0]?.categoryName);
        // requestor company values
        if (res?.ticket[0]?.requestor) {
          setRequestorLogo(res?.ticket[0]?.requestor?.logo || profilePic);
          setRequestor(res?.ticket[0]?.requestor?.organization_name || "...");
          setRequestorWeb(
            res?.ticket[0]?.requestor?.organization_website || "#"
          );
          setRequestorAddress(
            res?.ticket[0]?.requestor?.address || "No Address"
          );
          setRequestorId(res?.ticket[0]?.requestor?.id);
        } else {
          setRequestorLogo(profilePic);
          setRequestor(res?.ticket[0]?.requestor_org_name || "...");
          setRequestorWeb("#");
          setRequestorAddress(
            res?.ticket[0]?.requestor_org_phone || "No Phone Number"
          );
          setRequestorId(res?.ticket[0]?.requestor?.requestor_org_id);
        }

        // assigned tech
        setAssignId(res?.ticket[0]?.assign_tech?.id || "");
        setActiveStatus(res?.ticket[0]?.assign_tech?.online_status || "");
        setAssignName(
          res?.ticket[0]?.assign_tech?.username || "No Username Found"
        );
        setAssignDes(
          res?.ticket[0]?.assign_tech?.designation || "No Designation Found"
        );
        setAssignEmail(res?.ticket[0]?.assign_tech?.email || "No Email Found");
        setAssignPhone(res?.ticket[0]?.assign_tech?.phone || "No Phone Found");
        setAssignLogo(res?.ticket[0]?.assign_tech?.image || profilePic);
        setAssignCompLogo(res?.ticket[0]?.assign_tech?.logo || profilePic);
        if (res?.ticket[0]?.requestor_id?.designation === "Admin")
          setRequestorOrgId(res?.ticket[0]?.requestor_id?.id);
        else setRequestorOrgId(res?.ticket[0]?.requestor_id?.org_id);

        // requestor tech
        if (res?.ticket[0]?.requestor_id) {
          setRequesId(res?.ticket[0]?.requestor_id?.id || "");
          setRequesStatus(res?.ticket[0]?.requestor_id?.online_status || "");
          setRequesName(
            res?.ticket[0]?.requestor_id?.username || "No Username Found"
          );
          setRequesDes(
            res?.ticket[0]?.requestor_id?.designation || "No Designation Found"
          );
          setRequesEmail(
            res?.ticket[0]?.requestor_id?.email || "No Email Found"
          );
          setRequesPhone(
            res?.ticket[0]?.requestor_id?.phone || "No Phone Found"
          );
          setRequesLogo(res?.ticket[0]?.requestor_id?.image || profilePic);
        } else {
          setRequesId("");
          setRequesStatus("");
          setRequesName(res?.ticket[0]?.requestor_name || "No Username Found");
          setRequesDes("No Designation Found");
          setRequesEmail(res?.ticket[0]?.requestor_email || "No Email Found");
          setRequesPhone(res?.ticket[0]?.requestor_phone || "No Phone Found");
          setRequesLogo(profilePic);
        }
      } else
        toast(res?.message, {
          type: "error",
        });
    });
  };
  const reassign_tech = (tech_value, category_id) => {
    const tech_status = reassignTechApi(
      token,
      tech_value,
      ticketId,
      category_id
    );
    tech_status.then((res) => {
      if (res?.status) {
        getServiceTicketDetails();
        toast(res?.message, {
          type: "success",
        });
      } else {
        resetLoader();
        toast(res?.message, {
          type: "error",
        });
      }
    });
  };

  const close_ticket = (data) => {
    setLoader();
    const close_ticket_status = closeServiceTicket(token, data, ticketId);
    close_ticket_status.then((res) => {
      if (res?.status) {
        getServiceTicketDetails();
        toast(res?.message, {
          type: "success",
        });
      } else {
        resetLoader();
        toast(res?.message, {
          type: "error",
        });
      }
    });
  };

  const getData = () => {
    try {
      const data = getInternalMembers(token);
      data.then((res) => {
        if (res?.status) {
          const newArray = res?.data?.internal?.map((item) => {
            item["name"] = item?.username;
            return item;
          });
          const videoCallCreatorAdminData = newArray?.filter((item) => {
            return item?.role?.toString() === "admin";
          });
          setAvailableMember(newArray);
          setVideoCallCreatorAdmin(videoCallCreatorAdminData);
        }
      });
    } catch (error) {}
  };

  const getExternalData = () => {
    try {
      const data = getExternalMembers(token, requestorOrgId);
      data.then((res) => {
        if (res?.status) {
          const newArray = res?.data?.external?.map((item) => {
            item["name"] = item?.username;
            return item;
          });
          setAllExternalMember(newArray);
        }
      });
    } catch (error) {}
  };

  const checkInternalMember = (memberId) => {
    const allIds = availableMember?.map((item) => item?.id);
    if (allIds?.includes(memberId)) {
      return true;
    } else return false;
  };

  const openCreateChatDialog = (memberId) => {
    setLoader();
    if (checkInternalMember(memberId)) {
      getData();
      if (!selectedTeamMember.includes(user)) {
        const newUser = loggedInUser?.map((item) => {
          item["isCreator"] = true;
          item["isPermanent"] = true;
          return item;
        });
        newUser?.forEach((item) => {
          selectedTeamMember.push(item);
        });
      }
      const user_list_status = getUserList(token, [memberId.toString()]);
      user_list_status
        .then((res) => {
          resetLoader();
          res?.data?.forEach((item) => {
            if (!selectedTeamMember.includes(item)) {
              const newUser = res?.data?.map((item) => {
                item["isCreator"] = false;
                item["isPermanent"] = true;
                return item;
              });
              newUser?.forEach((item) => {
                selectedTeamMember.push(item);
              });
            }
          });
          setOpenInternalChat(true);
        })
        .catch((e) => {
          resetLoader();
          toast("Failed!, Try again later", { type: "error" });
        });
    } else {
      getExternalData();
      if (!selectedTeamMember.includes(user)) {
        const newUser = loggedInUser?.map((item) => {
          item["isCreator"] = true;
          item["isPermanent"] = true;
          return item;
        });
        newUser?.forEach((item) => {
          selectedTeamMember.push(item);
        });
      }
      const user_list_status = getUserList(token, [memberId.toString()]);
      user_list_status
        .then((res) => {
          resetLoader();
          res?.data?.forEach((item) => {
            if (!externalMember.includes(item)) {
              const newUser = res?.data?.map((item) => {
                item["isCreator"] = false;
                item["isPermanent"] = true;
                return item;
              });
              newUser?.forEach((item) => {
                externalMember.push(item);
              });
              setGroup_data(newUser);
            }
          });
          setOpenExternalChat(true);
        })
        .catch((e) => {
          resetLoader();
          toast("Failed!, Try again later", { type: "error" });
        });
    }
  };

  const deleteTicketFiles = (status) => {
    if (status === "Yes") {
      setLoader();
      const ticket_files = deletingTicketFiles(token, fileName);
      ticket_files.then((res) => {
        resetLoader();
        const ticket_data = serviceTicketDetails(token, selectedTicketId);
        ticket_data.then((res) => {
          setFiles(res?.ticket[0]?.files);
        });
      });
    }
  };

  return (
    <>
      <div className="stdetails-parent">
        <div className="stdetails-header">
          <Headers />
        </div>

        <div className="stdetails-navigation">
          <Navigation />
        </div>

        <div className="stdetails-main">
          <div className="stdetails-wrapper">
            <div className="stdetails-left">
              <div className="heading">
                <p>Ticket Details</p>
                {ticketStatus?.toString() === "closed" ? (
                  <span
                    style={{ textTransform: "capitalize", color: "orange" }}
                  >
                    Status: {ticketStatus}
                  </span>
                ) : (
                  <span style={{ textTransform: "capitalize" }}>
                    Status: {ticketStatus}
                  </span>
                )}
              </div>
              <div className="content">
                <div className="fields">
                  <label htmlFor="ticket-no">Ticket # (Auto Generate):</label>
                  <input type="text" id="ticket-no" value={ticketId} readOnly />
                </div>

                <div className="fields" style={{ pointerEvents: "none" }}>
                  <label htmlFor="severity">Severity Of Issue:</label>

                  <select
                    required
                    id="severity"
                    className="severity-wrapper"
                    value={severity}
                    readOnly
                  >
                    <option className="severity-row" value="none" disabled>
                      -- Select an option --
                    </option>
                    <option className="severity-row" value="urgent">
                      Urgent
                    </option>
                    <option className="severity-row" value="moderate">
                      Moderate
                    </option>
                    <option className="severity-row" value="low">
                      Low
                    </option>
                    <option className="severity-row" value="pause">
                      Pause
                    </option>
                    <option className="severity-row" value="closed">
                      Closed
                    </option>
                  </select>
                </div>

                <div className="fields">
                  <label htmlFor="created-at">Ticket Creation Date:</label>
                  <input
                    type="text"
                    id="created-at"
                    value={create_at}
                    readOnly
                  />
                </div>

                <div className="fields">
                  <label htmlFor="connected-foler">Connected Folder:</label>
                  <div className="fields-wrapper">
                    <input
                      type="text"
                      id="connected-foler"
                      value={folder}
                      readOnly
                    />
                    {folderLink &&
                      (folderLink?.includes("internal") ? (
                        current_org_id?.toString() ===
                          ticket_org_id?.toString() && (
                          <img
                            src={FolderLinkIcon}
                            alt="LinkButton"
                            onClick={() => {
                              if (folderLink?.includes("document/")) {
                                let length =
                                  folderLink?.split("document/").length;
                                let unique_id =
                                  folderLink?.split("document/")[length - 1];
                                let before_document =
                                  folderLink?.split("document/")[0];
                                if (before_document.includes("internal")) {
                                  // emptying the selected folder
                                  dispatch(selectedFolderDataAction(""));
                                  const internalFolderStatus =
                                    getInternalFolder(token);
                                  internalFolderStatus.then((res) => {
                                    dispatch(
                                      internalFolderDataAction(res?.result)
                                    );
                                    dispatch(
                                      selectedFolderRootDataAction(res?.result)
                                    );
                                  });
                                }
                                dispatch(
                                  currentFolderAutomationAction(unique_id)
                                );
                                navigate("/file-explorer");
                              }
                            }}
                          />
                        )
                      ) : (
                        <img
                          src={FolderLinkIcon}
                          alt="LinkButton"
                          onClick={() => {
                            if (folderLink?.includes("document/")) {
                              let length =
                                folderLink?.split("document/").length;
                              let unique_id =
                                folderLink?.split("document/")[length - 1];
                              dispatch(
                                currentFolderAutomationAction(unique_id)
                              );
                              navigate("/file-explorer");
                            }
                          }}
                        />
                      ))}
                  </div>
                </div>

                <div className="fields">
                  <label htmlFor="machine-serial">Machine Serial #:</label>
                  <input
                    type="text"
                    id="machine-serial"
                    value={machineNo}
                    readOnly
                  />
                </div>

                <div className="fields">
                  <label htmlFor="upload-files"> Upload Files:</label>
                  <div style={{ width: "100%" }}>
                    <div className="files-wrappper">
                      {files?.length !== 0 &&
                        files?.map((curr, inx) => {
                          const fileType = curr?.aws_name?.split(".").at(-1);
                          let icon = "";

                          switch (fileType) {
                            case "png":
                            case "jpg":
                            case "jpeg":
                              icon = imageIcon;
                              break;

                            case "pdf":
                              icon = pdfIcon;
                              break;

                            case "docx":
                            case "doc":
                              icon = officeIcon;
                              break;

                            case "xlsx":
                            case "xls":
                            case "csv":
                              icon = excelIcon;
                              break;

                            case "mp4":
                            case "mkv":
                            case "webm":
                              icon = videoFile;
                              break;

                            default:
                              icon = unknownFile;
                          }

                          return (
                            <>
                              <div className="file-upload">
                                <Link
                                  className="file-upload-link"
                                  to={`/app-file-viewer`}
                                  onClick={() => {
                                    localStorage.setItem(
                                      "filePath",
                                      curr?.path
                                    );
                                    localStorage.setItem(
                                      "fileType",
                                      curr?.aws_name?.split(".").at(1)
                                    );
                                  }}
                                >
                                  {icon !== "" ? (
                                    <img
                                      src={icon}
                                      alt="File Icon"
                                      className="file-upload-image"
                                    />
                                  ) : (
                                    <HelpCenterIcon />
                                  )}
                                  {curr?.aws_name
                                    ? curr?.aws_name
                                    : "unsupported format"}
                                </Link>
                                {loggedInUserDetails?.id === assignId ||
                                (loggedInUserDetails?.id !== requestorId &&
                                  loggedInUserDetails?.ticket_role ===
                                    "manager") ? (
                                  <span className="cancelIcon">
                                    <DeleteIcon
                                      className="cancelIconImage"
                                      onClick={() => {
                                        setConfirmPopup(true);
                                        setFileName(curr?.key);
                                      }}
                                    />
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          );
                        })}
                    </div>
                    {loggedInUserDetails?.ticket_role === "manager" ||
                    loggedInUserDetails?.id === requestorId ||
                    loggedInUserDetails?.id === assignId ? (
                      <input
                        type="file"
                        id="imageUpload"
                        multiple
                        onChange={(e) => {
                          let totalSize = 0;
                          for (let i = 0; i < e.target.files.length; i++) {
                            totalSize = totalSize + e.target.files[i].size;
                          }
                          if (isStorageLimitReached(totalSize))
                            return setShowStorageLimitReachedPopup(true);
                          setLoader();
                          const mult_file = addingMultipleFilesST(
                            token,
                            ticketId,
                            e.target.files,
                            "true"
                          );

                          mult_file.then((res) => {
                            res.json().then((response) => {
                              if (response?.status) {
                                if (response?.message?.includes("limit"))
                                  return toast(response?.message, {
                                    type: "info",
                                  });
                              } else {
                                toast(response?.message, { type: "info" });
                              }
                            });
                            resetLoader();
                            const ticket_data = serviceTicketDetails(
                              token,
                              selectedTicketId
                            );
                            ticket_data.then((res) => {
                              setFiles(res?.ticket[0]?.files);
                            });
                          });
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="fields">
                  <label htmlFor="description">Brief Description:</label>
                  <textarea
                    id="description"
                    rows="5"
                    value={desc}
                    readOnly
                  ></textarea>
                </div>

                {notes && notes?.length > 0 && (
                  <div className="fields">
                    <label htmlFor="ticket-notes">Notes:</label>
                    <div className="notes-content-area">
                      {notes?.length > 0 &&
                        notes?.map((curr, index) => {
                          return (
                            <div key={index}>
                              <p className="notes-head">
                                {curr?.username +
                                  "(" +
                                  curr?.organization_name +
                                  "): "}
                              </p>
                              <p className="notes-content">{curr?.note}</p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}

                {assignId?.toString() === my_id?.toString() && (
                  <>
                    <div className="fields">
                      <label htmlFor="additional-notes">
                        Additional Notes:
                      </label>
                      <div className="fields-additional-notes">
                        <textarea
                          id="additional-notes"
                          placeholder={
                            notesCharCounter > notesDefualtChar
                              ? "Add Notes"
                              : "Words Limit Reached!"
                          }
                          value={additionalNotes}
                          onChange={(e) => {
                            if (notesCharCounter > notesDefualtChar) {
                              setAdditionalNotes(e.target.value);
                            }
                          }}
                          rows="5"
                          maxLength={textAreaLength(
                            notesCharCounter - notesDefualtChar
                          )}
                        ></textarea>
                        <span>
                          {maxFileLength(
                            notesCharCounter,
                            additionalNotes,
                            notesDefualtChar
                          )}
                        </span>
                        {additionalNotes && (
                          <Button
                            variant="contained"
                            style={{ background: "#5ca127" }}
                            onClick={() => {
                              setLoader();
                              const post_notes = additionalNotesApi(
                                token,
                                ticketId,
                                additionalNotes
                              );

                              post_notes.then((res) => {
                                if (res?.status) {
                                  getServiceTicketDetails();
                                  setAdditionalNotes("");
                                  toast(res?.message, { type: "success" });
                                } else {
                                  resetLoader();
                                  toast(res?.message, { type: "error" });
                                }
                              });
                            }}
                          >
                            Post
                          </Button>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div className="btn-wrapper"></div>
              </div>
              {loggedInUserDetails?.ticket_role === "manager" &&
                ((serviceTicketType === "external" &&
                  requestorId !== loggedInUserDetails?.id) ||
                  serviceTicketType === "internal") && (
                  <div className="stdetails-bottom">
                    <div
                      onClick={() => {
                        if (ticketStatus?.toString() === "closed")
                          return toast("Ticket is Closed!", { type: "info" });
                        else if (
                          loggedInUserDetails?.external_ticket_role ===
                            "manager" &&
                          serviceTicketType === "external"
                        )
                          setReassignPopup(true);
                        else if (
                          loggedInUserDetails?.ticket_role === "manager" &&
                          serviceTicketType === "internal"
                        )
                          setReassignPopup(true);
                        else
                          toast("You do not have access", {
                            type: "info",
                          });
                      }}
                    >
                      <img src={reassignIcon} alt="reassign" />
                      <p>Reassign Ticket</p>
                    </div>
                    {serviceTicketType !== "internal" && (
                      <div
                        onClick={() => {
                          if (ticketStatus?.toString() === "closed")
                            return toast("Ticket is Closed!", { type: "info" });
                          if (ticketStatus?.toString() === "scheduled") {
                            toast("Ticket is already Scheduled!", {
                              type: "info",
                            });
                            return navigate("/appointment");
                          }
                          setAppointmentPopup(true);
                        }}
                      >
                        <img src={inspectionIcon} alt="schedule" />
                        <p>Schedule Meeting</p>
                      </div>
                    )}
                    {severity?.toString() === "pause" ? (
                      <div
                        ref={wrapperRef}
                        style={{ position: "relative" }}
                        onClick={() => {
                          if (ticketStatus?.toString() !== "closed") {
                            setDropUp(true);
                          } else {
                            toast("Ticket is Closed!", { type: "info" });
                          }
                        }}
                      >
                        <PlayCircleFilledWhiteIcon />
                        <p>Resume Ticket</p>

                        {/* select list to choose severity */}
                        {dropUp && (
                          <select
                            required
                            id="severity-resume"
                            className="severity-wrapper"
                            style={{
                              position: "absolute",
                              bottom: "35px",
                              left: "0",
                              width: "100%",
                              borderRadius: "5px",
                              outline: "none",
                              height: "35px",
                            }}
                            defaultValue="none"
                            onChange={(e) => {
                              setLoader();
                              const ticket_severity = updateSeverityApi(
                                token,
                                e.target.value,
                                ticketId
                              );
                              ticket_severity.then((res) => {
                                if (res?.status) {
                                  getServiceTicketDetails();
                                  toast(res?.message, {
                                    type: "success",
                                  });
                                } else {
                                  resetLoader();
                                  toast(res?.message, {
                                    type: "error",
                                  });
                                }
                              });
                            }}
                          >
                            <option
                              className="severity-row"
                              value="none"
                              disabled
                            >
                              -- Select an option --
                            </option>
                            <option className="severity-row" value="urgent">
                              Urgent
                            </option>
                            <option className="severity-row" value="moderate">
                              Moderate
                            </option>
                            <option className="severity-row" value="low">
                              Low
                            </option>
                          </select>
                        )}
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          if (ticketStatus?.toString() !== "closed") {
                            setLoader();
                            const ticket_severity = updateSeverityApi(
                              token,
                              "pause",
                              ticketId
                            );
                            ticket_severity.then((res) => {
                              if (res?.status) {
                                getServiceTicketDetails();
                                toast(res?.message, {
                                  type: "success",
                                });
                              } else {
                                resetLoader();
                                toast(res?.message, {
                                  type: "error",
                                });
                              }
                            });
                          } else {
                            toast("Ticket is Closed!", { type: "info" });
                          }
                        }}
                      >
                        <img src={pauseIcon} alt="pause-ticket" />
                        <p>Pause Ticket</p>
                      </div>
                    )}
                    {ticketStatus?.toString() !== "closed" ? (
                      <div
                        onClick={() => {
                          setClosePopup(true);
                        }}
                      >
                        <img src={closeIcon} alt="close-ticket" />
                        <p>Close Ticket</p>
                      </div>
                    ) : (
                      <div
                        ref={selectRef}
                        style={{ position: "relative" }}
                        onClick={() => {
                          setOpenTicketSelect(true);
                        }}
                      >
                        <PlayCircleFilledWhiteIcon />
                        <p>Re-Open Ticket</p>

                        {/* select list to choose severity */}
                        {openTicketSelect && (
                          <select
                            required
                            id="severity-reopen"
                            className="severity-wrapper"
                            style={{
                              position: "absolute",
                              bottom: "35px",
                              left: "0",
                              width: "100%",
                              borderRadius: "5px",
                              outline: "none",
                              height: "35px",
                            }}
                            defaultValue="none"
                            onChange={(e) => {
                              setLoader();
                              const ticket_severity = updateSeverityApi(
                                token,
                                e.target.value,
                                ticketId
                              );
                              ticket_severity.then((res) => {
                                if (res?.status) {
                                  getServiceTicketDetails();
                                  toast(res?.message, {
                                    type: "success",
                                  });
                                } else {
                                  resetLoader();
                                  toast(res?.message, {
                                    type: "error",
                                  });
                                }
                              });
                            }}
                          >
                            <option
                              className="severity-row"
                              value="none"
                              disabled
                            >
                              -- Select an option --
                            </option>
                            <option className="severity-row" value="urgent">
                              Urgent
                            </option>
                            <option className="severity-row" value="moderate">
                              Moderate
                            </option>
                            <option className="severity-row" value="low">
                              Low
                            </option>
                          </select>
                        )}
                      </div>
                    )}
                  </div>
                )}
            </div>
            <div className="stdetails-right">
              <div className="fields">
                <div className="content">
                  <div className="heading">
                    <p>ASSIGNED TO</p>
                  </div>
                  <div className="card">
                    <div className="card-logo">
                      <img
                        src={assignLogo}
                        alt="profile"
                        width="55px"
                        height="55px"
                      />

                      {activeStatus?.toString() === "online" ? (
                        <span
                          className="user-status"
                          style={{ color: "#87c823" }}
                        >
                          <FiberManualRecordIcon />
                        </span>
                      ) : (
                        <span
                          className="user-status"
                          style={{ color: "#c23616" }}
                        >
                          <FiberManualRecordIcon />
                        </span>
                      )}
                    </div>
                    <div className="card-text">
                      <h3>{assignName}</h3>
                      {activeStatus ? (
                        activeStatus?.toString() === "online" ? (
                          <p style={{ color: "#87c823" }}>{activeStatus}</p>
                        ) : (
                          <p style={{ color: "#ababab" }}>{activeStatus}</p>
                        )
                      ) : (
                        <p style={{ color: "#ababab" }}>No Activity Yet</p>
                      )}
                      <p>{assignDes}</p>
                      <p>{assignEmail}</p>
                      <p>{assignPhone}</p>
                    </div>

                    {assignId?.toString() !== my_id?.toString() && (
                      <div className="card-icon">
                        <IconButton
                          onClick={() => {
                            if (!assignId)
                              return toast("No assignee found", {
                                type: "info",
                              });
                            navigate("/chat");
                            dispatch(renderChatProfileAction(assignId));
                          }}
                        >
                          <ChatIcon
                            className="chat-icon"
                            style={{ color: "#507dd6" }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            if (!assignId)
                              return toast("No assignee found", {
                                type: "info",
                              });
                            setAssignEmailPopup(true);
                          }}
                        >
                          <EmailIcon
                            className="email-icon"
                            style={{ color: "#d1a02e" }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            if (!assignId)
                              return toast("No assignee found", {
                                type: "info",
                              });
                            dispatch(renderChatProfileAction(assignId));
                            openCreateChatDialog(assignId);
                          }}
                        >
                          <img src={VideoIcon} alt="VideoIcon" />
                        </IconButton>
                        {AssignEmailPopup && (
                          <RenderEmail
                            setEmailPopup={setAssignEmailPopup}
                            toEmail={assignEmail}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {serviceTicketType !== "internal" && (
                  <div className="content" style={{ marginTop: "35px" }}>
                    <div className="heading">
                      <p>REQUESTOR COMPANY</p>
                    </div>
                    <div className="card" style={{ padding: "15px 10px" }}>
                      <div className="card-logo">
                        <img
                          src={requestorLogo}
                          alt="profile"
                          width="55px"
                          height="55px"
                        />
                      </div>
                      <div className="card-text">
                        <h3 style={{ marginBottom: "5px" }}>{requestor}</h3>
                        {requestorWeb?.toString() === "#" ? null : (
                          <div
                            className="profile-url"
                            style={{ marginBottom: "5px" }}
                          >
                            <LanguageIcon />
                            <span style={{ display: "flex" }}>
                              Website:
                              {requestorWeb?.toString() === "#" ? (
                                "None"
                              ) : (
                                <a
                                  href={requestorWeb}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {requestorWeb}
                                </a>
                              )}
                            </span>
                            <IosShareIcon />
                          </div>
                        )}

                        <p>{requestorAddress}</p>
                      </div>
                    </div>
                  </div>
                )}

                <div className="content" style={{ marginTop: "35px" }}>
                  <div className="heading">
                    <p>REQUESTOR CONTACT</p>
                  </div>
                  <div className="card">
                    <div className="card-logo">
                      <img
                        src={requesLogo}
                        alt="profile"
                        width="55px"
                        height="55px"
                      />

                      {requesStatus?.toString() === "online" ? (
                        <span
                          className="user-status"
                          style={{ color: "#87c823" }}
                        >
                          <FiberManualRecordIcon />
                        </span>
                      ) : (
                        <span
                          className="user-status"
                          style={{ color: "#c23616" }}
                        >
                          <FiberManualRecordIcon />
                        </span>
                      )}
                    </div>
                    <div className="card-text">
                      <h3>{requesName}</h3>
                      {requesStatus ? (
                        requesStatus?.toString() === "online" ? (
                          <p style={{ color: "#87c823" }}>{requesStatus}</p>
                        ) : (
                          <p style={{ color: "#ababab" }}>{requesStatus}</p>
                        )
                      ) : (
                        <p style={{ color: "#ababab" }}>No Activity Yet</p>
                      )}
                      <p>{requesDes}</p>
                      <p>{requesEmail}</p>
                      <p>{requesPhone}</p>
                    </div>
                    {requesId?.toString() !== my_id?.toString() &&
                      doesExternalExist?.length > 0 &&
                      user?.scope !== "internal" && (
                        <div className="card-icon">
                          <IconButton
                            onClick={() => {
                              navigate("/chat");
                              dispatch(renderChatProfileAction(requesId));
                            }}
                          >
                            <ChatIcon
                              className="chat-icon"
                              style={{ color: "#507dd6" }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setEmailPopup(true);
                            }}
                          >
                            <EmailIcon
                              className="email-icon"
                              style={{ color: "#d1a02e" }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              if (!requesId) return;
                              dispatch(renderChatProfileAction(requesId));
                              openCreateChatDialog(requesId);
                            }}
                          >
                            <img src={VideoIcon} alt="VideoIcon" />
                          </IconButton>
                          {EmailPopup && (
                            <RenderEmail
                              setEmailPopup={setEmailPopup}
                              toEmail={requesEmail}
                            />
                          )}
                        </div>
                      )}
                  </div>
                </div>

                <div className="content" style={{ marginTop: "35px" }}>
                  <div className="heading">
                    <p>MESSAGE BOARD</p>
                  </div>
                  <div className="two-card">
                    {ticket_org_id &&
                      my_org_id &&
                      ticket_org_id?.toString() === my_org_id?.toString() && (
                        <div
                          style={{ marginRight: "25px" }}
                          onClick={() => {
                            dispatch(
                              ticketChatDataAction({
                                type: "internal",
                                ticket: ticketChatData,
                              })
                            );
                            navigate("/service-ticket-chat");
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              width: "fit-content",
                              marginBottom: "5px",
                            }}
                          >
                            <img src={assignCompLogo} alt="Organization" />
                            <TextsmsIcon style={{ color: "#507DD6" }} />
                          </div>

                          <span>Internal Group Chat</span>
                        </div>
                      )}

                    {/*  condition for external group */}

                    {serviceTicketType !== "internal" &&
                      doesExternalExist?.length > 0 && (
                        <div
                          onClick={() => {
                            dispatch(
                              ticketChatDataAction({
                                type: "external",
                                ticket: ticketChatData,
                              })
                            );
                            navigate("/service-ticket-chat");
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              width: "fit-content",
                              marginBottom: "5px",
                            }}
                          >
                            <img
                              src={assignCompLogo}
                              alt="Organization"
                              style={{ objectFit: "contain" }}
                            />
                            <img
                              src={requestorLogo}
                              alt="Organization"
                              style={{
                                position: "relative",
                                left: "-10px",
                                objectFit: "contain",
                              }}
                            />
                            <TextsmsIcon
                              style={{ color: "#507DD6", right: "0" }}
                            />
                          </div>

                          <span>External Group Chat</span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Reassign Ticket Popup */}
          {reassignPopup && (
            <STReassignPopup
              verify={reassign_tech}
              hide={setReassignPopup}
              org_id={ticket_org_id}
              users={assignName}
              user_id={assignId}
              type={serviceTicketType}
              category={categoryId}
              teamsName={categoryName}
            />
          )}

          {/* Close Ticket Popup */}
          {closePopup && (
            <RenderConfirm
              message={"close this ticket?"}
              data={"closed"}
              status={close_ticket}
              hide={setClosePopup}
            />
          )}

          {/* Schedule Popup */}
          {appointmentPopup && (
            <STAppointmentPopup
              hide={setAppointmentPopup}
              ticketId={ticketId}
              details={
                "Requestor Details:-\n" +
                requesName +
                "\n" +
                requesEmail +
                "\n" +
                requesPhone
              }
              token={token}
              requestorId={requestorId}
              requestorName={requestor}
            />
          )}
        </div>
      </div>
      <RenderCreateInternalChatRoom
        selectedTeamMember={selectedTeamMember}
        setSelectedTeamMember={setSelectedTeamMember}
        popup={openInternalChat}
        hide={setOpenInternalChat}
        availableMember={availableMember}
        videoCallCreatorAdmin={videoCallCreatorAdmin}
        toast={toast}
      />
      <RenderCreateExternalChatRoom
        selectedTeamMember={selectedTeamMember}
        setSelectedTeamMember={setSelectedTeamMember}
        popup={openExternalChat}
        hide={setOpenExternalChat}
        availableMember={availableMember}
        allExternalMember={allExternalMember}
        externalMember={externalMember}
        setExternalMember={setExternalMember}
        externalProfile={group_data[0]}
        videoCallCreatorAdmin={videoCallCreatorAdmin}
        toast={toast}
      />
      {showStorageLimitReachedPopup && (
        <StorageLimitReachedPopup hide={setShowStorageLimitReachedPopup} />
      )}
      {confirmPopup ? (
        <RenderConfirm
          message={"Delete it?"}
          data={"Yes"}
          status={deleteTicketFiles}
          hide={setConfirmPopup}
        />
      ) : null}
      {loader}
    </>
  );
};

export default STDetails;
