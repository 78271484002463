import "./NotificationCard.css";

import { Button, IconButton } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { useState } from "react";
import {
  actionConnectionRequest,
  approveRejectOrganisation,
  approveRejectRequest,
  deleteNotification,
  deletePortfolioVideoRateNotification,
  deletePurchasedSubscription,
  setNotificationReadApi,
} from "Config/API/action";
import {
  getExternalMembers,
  getFullNotification,
  getInternalMembers,
  getNotificationTabsTotalCount,
  serviceTicketDetails,
  statusOfRoom,
} from "Config/API/get";
import {
  getFullNotificationAction,
  getRecentNotificationCountAction,
} from "Redux/action/get.action";
import {
  raiseRequestIdAction,
  ticketIdAction,
} from "Redux/action/variable.action";
import {
  renderOrganizationInfo,
  ticketChatDataAction,
} from "Redux/action/render.action";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import Close from "Media/icons/Iconly-Bold-Close-Square.png";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import OrganisationInfoPopup from "Components/Popup/OrganisationInfoPopup/OrganisationInfoPopup";
import RenderAcceptExternalVideoChat from "Components/Popup/AcceptExternalVideoChat/Container/RenderAcceptExternalVideoChat";
import RenderCreateExternalChatRoom from "Components/Popup/CreateExternalChatRoom/Container/RenderCreateExternalChatRoom";
import Tick from "Media/icons/Iconly-Bold-Tick-Square.png";
import profilePic from "Media/blank-profile-picture.png";
import { storeVideoChatLinkAction } from "Redux/action/get.action";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";

const NotificationCard = () => {
  const [orgPopupInfo, setOrgPopupInfo] = useState({ data: {}, hide: false });
  const token = localStorage.getItem("token");
  const [showAcceptExternalChatPopup, setShowAcceptExternalChatPopup] =
    useState(false);
  const [selectedNotificationDetail, setSelectedNotificationDetail] =
    useState();
  const [selectedTeamMember, setSelectedTeamMember] = useState([]);
  const [openExternalChat, setOpenExternalChat] = useState(false);
  const [availableMember, setAvaiableMember] = useState([]);
  const [externalMember, setExternalMember] = useState([]);
  const [allExternalMember, setAllExternalMember] = useState([]);
  const [videoCallCreatorAdmin, setVideoCallCreatorAdmin] = useState([]);
  const [profile, setProfile] = useState([]);
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const userToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const loggedInUserId = useSelector(
    (state) => state.authenticationReducer?.id
  );
  const general_info = useSelector((state) => state.renderGeneralInfo);
  let recentNotificationList = useSelector(
    (state) => state.getFullNotificationReducer
  );
  const usableCredit = useSelector(
    (state) => state.getUsableCreditBalanceReducer
  );
  const loggedInUser = useSelector((state) => state.authenticationReducer);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  if (
    recentNotificationList === undefined ||
    recentNotificationList === null ||
    recentNotificationList === "" ||
    recentNotificationList?.length === 0
  ) {
    recentNotificationList = {};
  }

  const setNotificationReadApiHandler = () => {
    const response = setNotificationReadApi(token, searchParams.get("type"));
    response.then((res) => {
      if (res?.status) {
        const response = getNotificationTabsTotalCount(token);
        response.then((res) => {
          if (res?.status) {
            dispatch(
              getRecentNotificationCountAction(
                res?.TotalUnreadNotificationCount
              )
            );
          }
        });
      }
    });
  };

  const getNotificationByType = (type) => {
    const response = getFullNotification(token, type);
    response
      .then((res) => {
        resetLoader();
        if (res?.status) {
          dispatch(getFullNotificationAction(res?.data[1]?.notification));
          setNotificationReadApiHandler();
          toast("Notification Cleared", {
            type: "success",
          });
        } else {
          toast("Failed! Try again later", { type: "error" });
        }
      })
      .catch((e) => {
        resetLoader();
        toast("Failed! Try again later", { type: "error" });
      });
  };

  const sendAction = (conn_id, action_status, notification_id) => {
    const action_stat = actionConnectionRequest(
      conn_id,
      action_status,
      notification_id,
      userToken
    );
    action_stat
      .then((response) => {
        if (response?.status) {
          toast(response?.message, { type: "success" });
          getNotificationByType(searchParams.get("type"));
        } else toast(response?.message, { type: "error" });
      })
      .catch(() =>
        toast("Failed! Try again later", {
          type: "error",
        })
      );
  };

  // approve or reject portfolio request
  const acceptRejectPortfolioRequest = (action, folder, id) => {
    const response = approveRejectRequest(userToken, action, folder, id);
    response.then((res) => {
      if (res.status) {
        getNotificationByType(searchParams.get("type"));
        toast(res?.data?.message, {
          type: "success",
        });
      } else
        toast(res?.data?.message, {
          type: "error",
        });
    });
  };
  const joinRoom = (item) => {
    const role = "participant";
    const token = item?.mstoken;
    const id = item?.folder;
    const room_status = statusOfRoom(userToken, token, id);
    room_status.then((res) => {
      const name = res?.data?.name;
      if (res?.status) {
        if (res?.data.enabled) {
          navigate("/video-chat");
          dispatch(
            storeVideoChatLinkAction({
              token,
              id,
              name,
              role,
              from: "videoChat",
            })
          );
        } else {
          toast("Link is expired! Create a new Video Chat", { type: "error" });
        }
      } else {
        toast("Link is expired! Create a new Video Chat", { type: "error" });
      }
    });
  };

  const acceptCreatedRoom = (item) => {
    if (item?.conn_info?.role?.toString() === "admin") {
      if (
        item?.conn_info?.id?.toString() === item?.organization_id?.toString()
      ) {
        joinRoom(item);
        return null;
      } else {
        if (item?.is_billable?.toString() === "true") {
          setSelectedNotificationDetail(item);
          if (usableCredit < +item?.billing_rate)
            return toast("You do not have enough Credit to Join the Call", {
              type: "info",
            });
          setShowAcceptExternalChatPopup(true);
          return null;
        } else {
          joinRoom(item);
          return null;
        }
      }
    } else if (
      item?.conn_info?.org_id?.toString() === item?.organization_id?.toString()
    ) {
      joinRoom(item);
      return null;
    } else {
      if (item?.is_billable?.toString() === "true") {
        setSelectedNotificationDetail(item);
        if (usableCredit < +item?.billing_rate)
          return toast("You do not have enough Credit to Join the Call", {
            type: "info",
          });
        setShowAcceptExternalChatPopup(true);
        return null;
      } else {
        joinRoom(item);
        return null;
      }
    }
  };

  const acceptButtonClick = () => {
    joinRoom(selectedNotificationDetail);
    setShowAcceptExternalChatPopup(false);
  };

  const getData = () => {
    try {
      const data = getInternalMembers(userToken);
      data.then((res) => {
        if (res?.status) {
          const newArray = res?.data?.internal?.map((item) => {
            item["name"] = item?.username;
            return item;
          });
          const videoCallCreatorFilter = newArray?.filter((item) => {
            return item?.id?.toString() === loggedInUserId?.toString();
          });
          const videoCallCreator = videoCallCreatorFilter?.map((item) => {
            item["isCreator"] = true;
            item["isPermanent"] = true;
            return item;
          });
          const videoCallCreatorAdminData = newArray?.filter((item) => {
            return item?.role?.toString() === "admin";
          });
          setAvaiableMember(newArray);
          setSelectedTeamMember(videoCallCreator);
          setVideoCallCreatorAdmin(videoCallCreatorAdminData);
        }
      });
    } catch (error) {}
  };

  const getExternalData = (userData) => {
    const organization_id =
      userData?.conn_info?.role?.toString() === "admin"
        ? userData?.conn_info?.id
        : userData?.conn_info?.org_id;
    try {
      const data = getExternalMembers(userToken, organization_id);
      data.then((res) => {
        if (res?.status) {
          const newArray = res?.data?.external?.map((item) => {
            item["name"] = item?.username;
            return item;
          });
          const videoCallSendToFilter = newArray?.filter((item) => {
            return item?.id?.toString() === userData?.conn_info?.id?.toString();
          });
          const videoCallSendTo = videoCallSendToFilter?.map((item) => {
            item["isCreator"] = false;
            item["isPermanent"] = true;
            return item;
          });
          const videoCallSendToAdminData = newArray?.filter((item) => {
            return item?.role?.toString() === "admin";
          });
          setExternalMember(videoCallSendTo);
          setProfile(...videoCallSendToAdminData);
          setAllExternalMember(newArray);
        }
      });
    } catch (error) {}
  };

  const openCreateChatDialog = async (item) => {
    getData();
    getExternalData(item);
    setOpenExternalChat(true);
  };

  const videoChatHeader = (item) => {
    if (item?.conn_info?.role?.toString() === "admin") {
      if (
        item?.conn_info?.id?.toString() === item?.organization_id?.toString()
      ) {
        return item?.conn_info?.username;
      } else return item?.conn_info?.organization_name;
    } else if (
      item?.conn_info?.org_id?.toString() === item?.organization_id?.toString()
    ) {
      return item?.conn_info?.username;
    } else return item?.conn_info?.organization_name;
  };

  const profilePicSelector = (item) => {
    if (item?.conn_info?.role?.toString() === "admin") {
      if (
        item?.conn_info?.id?.toString() === item?.organization_id?.toString()
      ) {
        return item?.conn_info?.image ? item?.conn_info?.image : profilePic;
      } else return item?.conn_info?.logo ? item?.conn_info?.logo : profilePic;
    } else if (
      item?.conn_info?.org_id?.toString() === item?.organization_id?.toString()
    ) {
      return item?.conn_info?.image ? item?.conn_info?.image : profilePic;
    } else return item?.conn_info?.logo ? item?.conn_info?.logo : profilePic;
  };

  const subHeader = (item) => {
    if (item?.conn_info?.role?.toString() === "admin") {
      if (
        item?.conn_info?.id?.toString() === item?.organization_id?.toString()
      ) {
        return "";
      } else return item?.conn_info?.username ? item?.conn_info?.username : "";
    } else if (
      item?.conn_info?.org_id?.toString() === item?.organization_id?.toString()
    ) {
      return "";
    } else return item?.conn_info?.username ? item?.conn_info?.username : "";
  };

  const approveRejectOrganisationApiHandler = async (curr, approveStatus) => {
    setLoader();
    const response = await approveRejectOrganisation(
      curr?.folder,
      approveStatus,
      curr?.id,
      token
    );
    if (response?.status === 200) {
      toast(response?.data?.message, { type: "success" });
      getNotificationByType(searchParams.get("type"));
      resetLoader();
    } else {
      toast(response?.data?.message, {
        type: "error",
      });
      resetLoader();
    }
  };

  const timeStamp = (createdAt) => {
    if (!createdAt) return;
    const datetime = new Date(createdAt);
    const date = datetime.toLocaleDateString("en-US");
    const time = datetime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return (
      <span className="notification-card-timestamp">
        {time}, {date.split("/").join("-")}
      </span>
    );
  };

  return (
    <>
      {Object.keys(recentNotificationList).length?.toString() !== "0" ? (
        recentNotificationList?.map((curr, index) => {
          return (
            <div className="notification-card-wrapper" key={index}>
              <div
                className="notification-card-info notification-grid"
                onClick={() => {
                  // Conditions for handling notification redirection
                  if (curr?.type === "workbook")
                    return navigate({
                      pathname: `/portfolio/orgId=${general_info?.id}`,
                      search: `?type=workbook&folderId=${curr?.folder}`,
                    });
                  if (
                    curr?.tag?.toString() === "New Connection Request" ||
                    curr?.tag?.toString() === "Request Accepted" ||
                    curr?.tag?.toString() === "Request Rejected"
                  ) {
                    navigate("/organization", {
                      state: {
                        data: curr?.folder,
                      },
                    });
                    dispatch(renderOrganizationInfo({}));
                  } else if (curr?.type === "appointment") {
                    navigate("/appointment");
                  } else if (curr?.tag?.toString() === "Service Ticket") {
                    dispatch(ticketIdAction(curr?.folder));
                    navigate("/service-ticket-details");
                  } else if (curr?.tag?.toString() === "Service Ticket Chat") {
                    let chat_type = "";
                    chat_type = curr?.scope;
                    const ticket_data = serviceTicketDetails(
                      token,
                      curr?.ticket_id
                    );
                    ticket_data.then((response) => {
                      if (response?.status) {
                        dispatch(
                          ticketChatDataAction({
                            type: chat_type,
                            ticket: response?.ticket[0],
                          })
                        );
                        navigate("/service-ticket-chat");
                      }
                    });
                  }
                }}
              >
                <div className="notification-logo">
                  {curr?.tag?.toString() === "Video Chat Room Created" ? (
                    <img src={profilePicSelector(curr)} alt="logo" />
                  ) : curr?.tag?.toString() === "Video Chat Requested" ? (
                    <img
                      src={
                        curr.conn_info?.logo
                          ? curr?.conn_info?.logo
                          : profilePic
                      }
                      alt="logo"
                    />
                  ) : curr?.tag?.toString() === "Video Call Rejected" ? (
                    <img src={profilePicSelector(curr)} alt="logo" />
                  ) : (
                    <img
                      src={
                        curr?.conn_info?.logo
                          ? curr?.conn_info?.logo
                          : profilePic
                      }
                      alt="logo"
                    />
                  )}
                </div>
                <div className="notification-content">
                  {curr?.tag?.toString() === "Cancel Appointment" ? (
                    <div
                      className="notification-type"
                      style={{ backgroundColor: "#e67e22" }}
                    >
                      Cancelled Appointment
                    </div>
                  ) : (
                    <div className="notification-type">{curr?.tag}</div>
                  )}
                  <div className="notification-data">
                    {curr?.tag?.toString() === "New Appointment" ? (
                      <h3>Created By: {curr?.conn_info?.organization_name}</h3>
                    ) : curr?.tag?.toString() === "Cancel Appointment" ? (
                      <h3>
                        Cancelled By: {curr?.conn_info?.organization_name}
                      </h3>
                    ) : curr?.tag?.toString() === "Video Chat Room Created" ? (
                      <h3>{videoChatHeader(curr)}</h3>
                    ) : curr?.tag?.toString() === "Video Chat Requested" ? (
                      <h3>{curr?.conn_info?.organization_name}</h3>
                    ) : curr?.tag?.toString() === "Video Call Rejected" ? (
                      <h3>{videoChatHeader(curr)}</h3>
                    ) : (
                      <h3>{curr?.conn_info?.organization_name}</h3>
                    )}
                    <p>
                      {curr?.type?.toLowerCase() === "appointment" ? (
                        <>
                          <b>Appointment: </b>
                          {curr?.msg}
                        </>
                      ) : curr?.tag?.toString() ===
                        "Video Chat Room Created" ? (
                        subHeader(curr)
                      ) : curr?.tag?.toString() === "Video Chat Requested" ? (
                        curr?.conn_info?.username
                      ) : curr?.tag?.toString() === "Video Call Rejected" ? (
                        subHeader(curr)
                      ) : (
                        curr?.msg
                      )}
                    </p>
                  </div>
                </div>
              </div>

              {curr?.tag?.toString() === "New Organization Registered" ? (
                <div style={{ display: "grid", placeItems: "center" }}>
                  <div className="approve-reject-organisation-actions">
                    <button
                      onClick={() =>
                        approveRejectOrganisationApiHandler(curr, "rejected")
                      }
                      style={{ border: "none", background: "transparent" }}
                    >
                      <img src={Close} alt="Reject" />
                    </button>
                    <button
                      onClick={() =>
                        approveRejectOrganisationApiHandler(curr, "approved")
                      }
                      style={{ border: "none", background: "transparent" }}
                    >
                      <img src={Tick} alt="Success" />
                    </button>
                  </div>
                  {timeStamp(curr?.createdAt)}
                </div>
              ) : curr?.tag?.toString() === "New Connection Request" ? (
                <div className="notification-options notification-flex">
                  <div
                    className="notification-reject"
                    onClick={() =>
                      sendAction(curr?.conn_id, "rejected", curr?.id)
                    }
                  >
                    <img src={Close} alt="Reject" />
                  </div>
                  <div
                    className="notification-accept"
                    onClick={() =>
                      sendAction(curr?.conn_id, "connected", curr?.id)
                    }
                  >
                    <img src={Tick} alt="Success" />
                  </div>
                </div>
              ) : curr?.tag?.toString() ===
                "New portfolio Subscription Request" ? (
                <div
                  className="notification-body-top"
                  style={{ justifyContent: "end" }}
                >
                  <div className="notification-options notification-flex">
                    <div
                      className="notification-reject"
                      onClick={() =>
                        acceptRejectPortfolioRequest(
                          "rejected",
                          curr?.folder,
                          curr?.id
                        )
                      }
                    >
                      <img src={Close} alt="Reject" />
                    </div>
                    <div
                      className="notification-accept"
                      onClick={() =>
                        acceptRejectPortfolioRequest(
                          "approved",
                          curr?.folder,
                          curr?.id
                        )
                      }
                    >
                      <img src={Tick} alt="Success" />
                    </div>
                  </div>
                  <span className="notification-date">
                    {timeStamp(curr?.createdAt)}
                  </span>
                </div>
              ) : curr?.tag?.toString() === "Video Chat Room Created" ? (
                <div className="approve-reject-organisation-actions">
                  <button
                    onClick={() => acceptCreatedRoom(curr)}
                    style={{ border: "none", background: "transparent" }}
                  >
                    <img src={Tick} alt="Success" />
                  </button>
                  <button
                    style={{ border: "none", background: "transparent" }}
                    onClick={() => {
                      setLoader();
                      const notification_status = deleteNotification(
                        userToken,
                        curr?.id
                      );
                      notification_status
                        .then((res) => {
                          if (res?.status) {
                            if (
                              res?.message
                                ?.toLowerCase()
                                .includes("room is currently active")
                            ) {
                              resetLoader();
                              toast(res?.message, {
                                type: "info",
                              });
                            } else {
                              toast("Request Rejected", { type: "success" });
                              getNotificationByType(searchParams.get("type"));
                            }
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                            resetLoader();
                          }
                        })
                        .catch((e) => {
                          resetLoader();

                          toast("Failed! Try Again Later", {
                            type: "error",
                          });
                        });
                    }}
                  >
                    <img src={Close} alt="Reject" />
                  </button>
                </div>
              ) : curr?.tag?.toString() === "Video Chat Requested" ? (
                <>
                  {loader}
                  <div style={{ display: "grid", gap: "5px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <div className="video-chat-room-requested-tag">
                        <button
                          onClick={() => openCreateChatDialog(curr)}
                          style={{ borderColor: "#00000040", color: "black" }}
                        >
                          CREATE CHATROOM
                        </button>
                      </div>
                      <button
                        className="video-chat-room-delete-button "
                        onClick={() => {
                          setLoader();
                          const notification_status = deleteNotification(
                            userToken,
                            curr?.id
                          );
                          notification_status
                            .then((res) => {
                              if (res?.status) {
                                toast(res?.message, {
                                  type: "success",
                                });
                                getNotificationByType(searchParams.get("type"));
                                resetLoader();
                              } else {
                                toast(res?.message, {
                                  type: "error",
                                });
                                resetLoader();
                              }
                            })
                            .catch((e) => {
                              resetLoader();
                              toast("Failed! Try Again Later", {
                                type: "error",
                              });
                            });
                        }}
                      >
                        <DeleteIcon color="error" fontSize="medium" />
                      </button>
                    </div>
                    {timeStamp(curr?.createdAt)}
                  </div>
                </>
              ) : curr?.tag?.toString() === "New Service Request" ? (
                <>
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    {loggedInUser?.ticket_role === "manager" &&
                      (curr?.requestType === "internal" ||
                        (curr?.requestType === "external" &&
                          loggedInUser?.id !== curr?.conn_info?.id)) && (
                        <div className="service-raise-request-icon">
                          <Link
                            to="/service-raise-request-info"
                            onClick={() => {
                              dispatch(raiseRequestIdAction(curr?.folder));
                            }}
                          >
                            {/* <ErrorOutlineIcon /> */}
                            <Button
                              variant="outlined"
                              style={{
                                color: "rgb(92, 161, 39)",
                                borderColor: "rgb(92, 161, 39)",
                              }}
                            >
                              Check Details
                            </Button>
                          </Link>
                        </div>
                      )}
                    {timeStamp(curr?.createdAt)}
                  </div>
                  <div className="delete-notification-ui">
                    <IconButton
                      onClick={() => {
                        setLoader();
                        const notification_status = deleteNotification(
                          userToken,
                          curr?.id
                        );
                        notification_status
                          .then((res) => {
                            if (res?.status) {
                              getNotificationByType(searchParams.get("type"));
                            } else {
                              toast(res?.message, {
                                type: "error",
                              });
                              resetLoader();
                            }
                          })
                          .catch((e) => {
                            resetLoader();
                            toast("Failed! Try Again Later", {
                              type: "error",
                            });
                          });
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </div>
                </>
              ) : curr?.tag?.toString() === "Subscription" ? (
                <>
                  <div
                    className="notification-body-top"
                    style={{ justifyContent: "end" }}
                  >
                    {curr?.msg?.includes(
                      "Do you wish to accept the new rate?"
                    ) && (
                      <div className="notification-options notification-flex">
                        <div
                          className="notification-reject"
                          onClick={() => {
                            setLoader();
                            const response = deletePurchasedSubscription(
                              token,
                              curr?.folder
                            );
                            response.then((res) => {
                              resetLoader();
                              if (res.status) {
                                toast(res.message, {
                                  type: "success",
                                });
                                const notification_status =
                                  deletePortfolioVideoRateNotification(
                                    userToken,
                                    curr?.folder
                                  );
                                notification_status
                                  .then((res) => {
                                    if (res?.status) {
                                      toast(res?.message, {
                                        type: "success",
                                      });
                                      getNotificationByType(
                                        searchParams.get("type")
                                      );
                                    } else {
                                      toast(res?.message, {
                                        type: "error",
                                      });
                                    }
                                  })
                                  .catch((e) => {
                                    toast("Failed! Try Again Later", {
                                      type: "error",
                                    });
                                  });
                              } else {
                                toast("Failed! Try again later", {
                                  type: "error",
                                });
                              }
                            });
                          }}
                        >
                          <img src={Close} alt="Reject" />
                        </div>
                        <div
                          className="notification-accept"
                          onClick={() => {
                            toast("Subscription Added Successfully", {
                              type: "success",
                            });
                            const notification_status =
                              deletePortfolioVideoRateNotification(
                                userToken,
                                curr?.folder
                              );
                            notification_status
                              .then((res) => {
                                if (res?.status) {
                                  toast(res?.message, {
                                    type: "success",
                                  });
                                  getNotificationByType(
                                    searchParams.get("type")
                                  );
                                } else {
                                  toast(res?.message, {
                                    type: "error",
                                  });
                                }
                              })
                              .catch((e) => {
                                toast("Failed! Try Again Later", {
                                  type: "error",
                                });
                              });
                          }}
                        >
                          <img src={Tick} alt="Success" />
                        </div>
                      </div>
                    )}
                    {timeStamp(curr?.createdAt)}
                  </div>
                  <div className="delete-notification-ui">
                    <IconButton
                      onClick={() => {
                        setLoader();
                        const notification_status = deleteNotification(
                          userToken,
                          curr?.id
                        );
                        notification_status
                          .then((res) => {
                            if (res?.status) {
                              getNotificationByType(searchParams.get("type"));
                            } else {
                              toast(res?.message, {
                                type: "error",
                              });
                              resetLoader();
                            }
                          })
                          .catch((e) => {
                            resetLoader();
                            toast("Failed! Try Again Later", {
                              type: "error",
                            });
                          });
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </div>
                </>
              ) : (
                <>
                  {/* {loader} */}
                  <div className="delete-notification-ui">
                    <IconButton
                      onClick={() => {
                        setLoader();
                        const notification_status = deleteNotification(
                          userToken,
                          curr?.id
                        );
                        notification_status
                          .then((res) => {
                            if (res?.status) {
                              getNotificationByType(searchParams.get("type"));
                            } else {
                              toast(res?.message, {
                                type: "error",
                              });
                              resetLoader();
                            }
                          })
                          .catch((e) => {
                            resetLoader();
                            toast("Failed! Try Again Later", {
                              type: "error",
                            });
                          });
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </div>
                  {timeStamp(curr?.createdAt)}
                </>
              )}
              <RenderAcceptExternalVideoChat
                popup={showAcceptExternalChatPopup}
                hide={setShowAcceptExternalChatPopup}
                accept={acceptButtonClick}
                rate={selectedNotificationDetail?.billing_rate}
              />
              {general_info?.org_id?.toString() === "-1" &&
                curr?.type === "registered" && (
                  <button
                    onClick={() =>
                      setOrgPopupInfo({ data: curr?.conn_info, hide: true })
                    }
                    className="notification-card-info-icon"
                  >
                    <InfoIcon fontSize="small" />
                  </button>
                )}
            </div>
          );
        })
      ) : (
        <p className="footer-para">No Notifications to Show</p>
      )}
      <RenderCreateExternalChatRoom
        selectedTeamMember={selectedTeamMember}
        setSelectedTeamMember={setSelectedTeamMember}
        popup={openExternalChat}
        hide={setOpenExternalChat}
        availableMember={availableMember}
        allExternalMember={allExternalMember}
        externalMember={externalMember}
        setExternalMember={setExternalMember}
        externalProfile={profile}
        videoCallCreatorAdmin={videoCallCreatorAdmin}
        toast={toast}
      />
      {loader}
      {orgPopupInfo.hide && (
        <OrganisationInfoPopup
          data={orgPopupInfo.data}
          setOrgPopupInfo={setOrgPopupInfo}
        />
      )}
    </>
  );
};

export default NotificationCard;
