import "./OrganisationUI.css";

import { Button, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getAllAmniusAdmin,
  getAllSubscriptions,
  getOrganizationData,
} from "Config/API/get";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import AddAmniusAdmin from "Components/Popup/AddAmniusAdmin/AddAmniusAdmin";
import Headers from "Components/Header/Headers";
import HideSourceIcon from "@mui/icons-material/HideSource";
import IosShareIcon from "@mui/icons-material/IosShare";
import LanguageIcon from "@mui/icons-material/Language";
import Navigation from "Components/SideNavBar/Navigation";
import RenderAddUser from "Components/FileExplorer/AddUser/Container/RenderAddUser";
import RenderCard from "Components/Cards/ProfileCard/Container/RenderProfileCard";
import RenderSTInternalRaiseRequestForm from "Components/Popup/STRaiseInternalRequestForm/Container/RenderSTRaiseRequestForm";
import RenderSTRaiseRequestForm from "Components/Popup/STRaiseRequestForm/Container/RenderSTRaiseRequestForm";
import RequestPendingIcon from "Media/icons/req-pending.png";
import SendRequestIcon from "Media/icons/send-req.png";
import TabContent from "Components/TabContent/TabContent";
import UserManagementIcon from "Media/team-management (1)@2x.png";
import VerifiedIcon from "@mui/icons-material/Verified";
import { hashIdAction } from "Redux/action/variable.action";
import profilePic from "Media/blank-profile-picture.png";
import { renderOrganizationInfo } from "Redux/action/render.action";
import { requestConnection } from "Config/API/action";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";
import useIsAmniusOrganization from "Hooks/useIsAmniusOrganization";

const OrganisationUI = ({ general_info, role }) => {
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openAddAmniusAdmin, setOpenAddAmniusAdmin] = useState(false);
  const [requestPopup, setRequestPopup] = useState(false);
  const [amniusAdmins, setAmniusAdmins] = useState([]);
  const [adminLoading, setAdminLoading] = useState(false);
  const [adminLoading2, setAdminLoading2] = useState(false);
  const [internalRequestPopup, setInternalRequestPopup] = useState(false);
  const [serviceTicketSubscription, setServiceTicketSubscription] = useState(
    []
  );
  const token = localStorage.getItem("token");
  const isOrgAmnius = useIsAmniusOrganization();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const loggedInUser = useSelector((state) => state.authenticationReducer);
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  if (
    general_info === undefined ||
    general_info === null ||
    general_info === "" ||
    general_info?.length === 0
  ) {
    general_info = {};
  }
  useEffect(() => {
    getAllAvailableSubscriptions();
    if ((isOrgAmnius && role !== "other") || state?.data?.toString() === "-1")
      getAmniusAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state?.from === "navigation") return;
    getVisitedOrganizationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const getAllAvailableSubscriptions = () => {
    setLoader();
    const response = getAllSubscriptions(token);
    response.then((res) => {
      resetLoader();
      if (res.data.status) {
        const filteredSubscription = res.data.data?.filter(
          (item) => item?.subscription_type === "service_ticket"
        );
        setServiceTicketSubscription(filteredSubscription[0]);
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };
  const getVisitedOrganizationData = () => {
    setLoader();
    setAdminLoading2(true);
    const otherOrgData = getOrganizationData(token, state?.data);
    otherOrgData
      .then((res) => {
        setAdminLoading2(false);
        resetLoader();
        if (res?.status)
          dispatch(renderOrganizationInfo(res?.organizationData));
        else {
          toast(res?.message, { type: "error" });
          navigate(-1);
        }
      })
      .catch((e) => resetLoader());
  };

  const getAmniusAdmin = () => {
    setAdminLoading(true);
    const getAmniusAdminResponse = getAllAmniusAdmin(token);
    getAmniusAdminResponse.then((res) => {
      setAdminLoading(false);
      if (res?.data?.status) {
        setAmniusAdmins(res?.data?.data);
      }
    });
  };

  const sendRequest = (conn_id) => {
    setLoader();
    const request_status = requestConnection(conn_id, token);
    request_status
      .then((response) => {
        if (response?.status) {
          resetLoader();
          toast(response?.message, {
            type: "success",
          });
          const otherOrgData = getOrganizationData(token, conn_id);
          otherOrgData.then((res) => {
            dispatch(renderOrganizationInfo(res?.organizationData));
          });
        } else {
          resetLoader();
          toast(response?.message, { type: "error" });
        }
      })
      .catch(() => {
        resetLoader();
        toast("Failed! Try again later", {
          type: "error",
        });
      });
  };

  //organisation left card
  const orgLeft = () => {
    return (
      <div className="org-wrapper">
        <div className="org-upper" style={{ justifyContent: "space-between" }}>
          <div className="org-head">
            {role?.toString() === "other"
              ? "Organization Details"
              : "Admin Settings"}
          </div>
          <div className="org-status-action-wrapper">
            {role?.toString() === "other" &&
              state?.data?.toString() !== "-1" && (
                <div
                  className={
                    general_info?.isConnected === "connected"
                      ? "org-view_all"
                      : "org-not-connected"
                  }
                >
                  {general_info?.isConnected === "connected" ? (
                    <div>
                      CONNECTED <VerifiedIcon />
                    </div>
                  ) : (
                    <div>
                      NOT CONNECTED <HideSourceIcon />
                    </div>
                  )}
                </div>
              )}
            {general_info?.isConnected === "pending" && (
              <Tooltip title="Request Pending" placement="top" arrow>
                <Button
                  variant="contained"
                  className={role?.toString() === "other" && "org-request-btn"}
                  style={{
                    background: "#C4A21C",
                    border: "solid 2px #C4A21C",
                    height: "100%",
                  }}
                >
                  <img
                    style={{ width: "26px" }}
                    src={RequestPendingIcon}
                    alt="Request Pending Icon"
                  />
                </Button>
              </Tooltip>
            )}
            {general_info?.isConnected === "not-connected" &&
              state?.data?.toString() !== "-1" &&
              loggedInUser?.scope !== "internal" && (
                <Tooltip title="Send Request" placement="top" arrow>
                  <Button
                    variant="contained"
                    className={
                      role?.toString() === "other" && "org-request-btn"
                    }
                    style={{
                      background: "rgb(92, 161, 39)",
                      border: "solid 2px rgb(92, 161, 39)",
                      height: "100%",
                    }}
                    onClick={() => {
                      sendRequest(general_info?.id);
                    }}
                  >
                    <img
                      style={{ width: "26px" }}
                      src={SendRequestIcon}
                      alt="Send Request Icon"
                    />
                  </Button>
                </Tooltip>
              )}
          </div>
        </div>
        <div className="org-lower">
          <div className="org-admin-details">
            <div className="org-logo">
              <img
                src={general_info.logo ? general_info.logo : profilePic}
                alt="profile"
                width="120px"
                height="120px"
              />
            </div>
            <div className="org-bio">
              <div className="org-text">
                <h3>
                  {general_info.organization_name
                    ? general_info.organization_name
                    : "Organization Name"}
                </h3>
                <div className="org-url">
                  <LanguageIcon />
                  <span>
                    Website:
                    <a
                      href={
                        general_info.organization_website
                          ? general_info.organization_website
                          : null
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {general_info.organization_website
                        ? general_info.organization_website
                        : null}
                    </a>
                  </span>
                  <IosShareIcon />
                </div>
                {general_info?.address?.replaceAll(", ", "")?.length > 0 ? (
                  <p>
                    {general_info?.street}
                    {general_info?.city && `, ${general_info?.city}`}
                    {general_info?.state && `, ${general_info?.state}`}
                    {general_info?.country && `, ${general_info?.country}`}
                    {general_info?.zipcode && `, ${general_info?.zipcode}`}
                  </p>
                ) : (
                  "No address to Show"
                )}
              </div>
            </div>
          </div>
          <div className="org-admin-hover-wrapper">
            <Button
              variant="contained"
              className={role?.toString() === "other" && "org-request-btn"}
              style={{ background: "rgb(92, 161, 39) " }}
              onClick={() => {
                navigate({
                  pathname: `/portfolio/orgId=${
                    general_info?.super_org_id === "-1"
                      ? "-1"
                      : general_info?.id
                  }`,
                  search: "?type=portfolio",
                });
              }}
            >
              Portfolio
            </Button>
            {role?.toString() === "other" ? (
              <>
                {loggedInUser?.scope !== "internal" &&
                  general_info?.isServiceTicketSubscription && (
                    <Button
                      variant="contained"
                      className={
                        role?.toString() === "other" && "org-request-btn"
                      }
                      style={{ background: "rgb(92, 161, 39) " }}
                      onClick={() => {
                        if (loggedInUser?.external_ticket_role === "manager")
                          setRequestPopup(true);
                        else
                          toast("You do not have access", {
                            type: "info",
                          });
                      }}
                    >
                      REQUEST SERVICE
                    </Button>
                  )}
                {general_info?.isConnected === "connected" && (
                  <Button
                    variant="contained"
                    className={
                      role?.toString() === "other" && "org-request-btn"
                    }
                    style={{ background: "#5ca127" }}
                    onClick={() => {
                      navigate("/file-explorer");
                      dispatch(hashIdAction(general_info?.id));
                    }}
                  >
                    GO TO FOLDER
                  </Button>
                )}
              </>
            ) : (
              <Button
                variant="contained"
                className={role?.toString() === "other" && "org-request-btn"}
                style={{ background: "rgb(92, 161, 39) " }}
                onClick={() => {
                  if (
                    serviceTicketSubscription?.subscriptionStatus?.toString() ===
                    "true"
                  )
                    setInternalRequestPopup(true);
                  else
                    toast("You are not subscribed to service ticketing", {
                      type: "info",
                    });
                }}
              >
                REQUEST SERVICE
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  // organisation right card
  const orgRight = () => {
    return (
      <div className="org-wrapper">
        <div className="org-upper">
          <div className="org-head">Admin And Team Details</div>
          <div className="org-request-butt">
            {role?.toString() === "other" ? (
              <Button
                variant="contained"
                style={{ background: "#5ca127", display: "none" }}
              >
                REQUEST SERVICE
              </Button>
            ) : null}
          </div>
        </div>
        <div className="org-lower">
          {renderAdminDetails(adminLoading2)}
          {renderTeamDetails()}
        </div>
      </div>
    );
  };

  // render admin details
  const renderAdminDetails = (adminLoading2) => {
    return (
      <div className="profile-details">
        <div className="profile-with-card">
          <div className="profile-tabs-wrapper">
            <div className="profile-banner">
              {(isOrgAmnius && role !== "other") ||
              state?.data?.toString() === "-1"
                ? "SUPER ADMIN"
                : "ADMIN"}
            </div>

            {isOrgAmnius && state?.data?.toString() === "-1"
              ? ""
              : role?.toString() !== "other" && (
                  <div
                    className="user-management-cta"
                    onClick={() => {
                      navigate("/service-ticket-user-management");
                    }}
                  >
                    <img src={UserManagementIcon} alt="Service-Ticket" />
                    User Management
                  </div>
                )}
          </div>
          <div className="profile-card-wrapper">
            {Object.keys(general_info).length?.toString() !== "0" ? (
              <RenderCard
                currEle={general_info}
                role={role}
                org_name={general_info?.organization_name}
                state={state}
                adminLoading2={adminLoading2}
              />
            ) : null}
          </div>
          {((isOrgAmnius && role !== "other") ||
            state?.data?.toString() === "-1") && (
            <>
              <div
                className="profile-text-wrapper"
                style={{ marginTop: "10px" }}
              >
                <div className="profile-banner">ADMIN</div>
                <div className="profile-add">
                  {role?.toString() === "super_admin" && (
                    <Button
                      variant="contained"
                      style={{ background: "#5ca127" }}
                      onClick={() => {
                        setOpenAddAmniusAdmin(true);
                      }}
                    >
                      +CREATE ADMIN
                    </Button>
                  )}
                </div>
              </div>
              <div className="profile-card-wrapper">
                {!adminLoading ? (
                  amniusAdmins.length > 0 ? (
                    amniusAdmins.map((item) => {
                      return (
                        <RenderCard
                          state={state}
                          currEle={item}
                          role={item?.role}
                          org_name={general_info?.organization_name}
                          showEdit={isOrgAmnius ? "show" : "hide"}
                        />
                      );
                    })
                  ) : (
                    <p>No Admin Found</p>
                  )
                ) : (
                  <>
                    <div className="org-card-container">
                      <div className="org-card-board"></div>
                    </div>
                    <div className="org-card-container">
                      <div className="org-card-board"></div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  //render team details
  const renderTeamDetails = () => {
    return (
      <div className="profile-details">
        <div className="profile-with-card">
          {/* <div className="profile-text-wrapper">
            <div className="profile-banner">TEAM</div>
            {role === "admin" || role === "super_admin" ? (
              <div className="profile-add">
                <Button
                  variant="contained"
                  style={{ background: "#5ca127" }}
                  onClick={() => {
                    setOpenAddUser(true);
                  }}
                >
                  +ADD USER
                </Button>
              </div>
            ) : null}
          </div>
          <div className="profile-card-wrapper">
            {Object.keys(team_data).length !== 0
              ? team_data?.map((currEle) => {
                  return renderProfileCardItem(currEle);
                })
              : null}
          </div> */}

          <TabContent
            role={role}
            setOpenAddUser={setOpenAddUser}
            general_info={general_info}
            state={state}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="org-container">
        <div className="org-header">
          <Headers />
        </div>

        <div className="org-navigation">
          <Navigation />
        </div>
        <div className="org-main">
          <div className="org-left">{orgLeft()}</div>
          <div className="org-right">{orgRight()}</div>
          <RenderAddUser popup={openAddUser} hide={setOpenAddUser} />
        </div>
      </div>

      {requestPopup ? (
        <RenderSTRaiseRequestForm
          hide={setRequestPopup}
          service_org_id={general_info?.id}
        />
      ) : null}

      {internalRequestPopup ? (
        <RenderSTInternalRaiseRequestForm
          hide={setInternalRequestPopup}
          service_org_id={general_info?.id}
        />
      ) : null}
      <AddAmniusAdmin popup={openAddAmniusAdmin} hide={setOpenAddAmniusAdmin} />
      {loader}
    </>
  );
};

export default OrganisationUI;
