import "App.css";
import "react-toastify/dist/ReactToastify.css";

import { Slide, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import AlertPopup from "Components/Alert/AlertPopup/AlertPopup";
import AppRoutes from "Routes/App.Routes";
import VerificationDialog from "Components/Alert/DialogBox/VerificationDialog";
import firebase from "firebase/compat/app";
import { getNotificationTabsTotalCount } from "Config/API/get";
import { getRecentNotificationCountAction } from "Redux/action/get.action";
import { messaging } from "Constants/FirebaseConfig";
import { newChatAlert } from "Redux/action/alert.action";
import { onMessage } from "firebase/messaging";
import { requestForNotificationToken } from "Constants/PushNotificationRenderer";
import { toast } from "react-toastify";
import { useEffect } from "react";

window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const loggedInUserId = useSelector(
    (state) => state?.authenticationReducer?.id
  );

  firebase.initializeApp({
    apiKey: "AIzaSyAvccGlSfJnEAgzn9CmOeCnZrhJTyh9vbA",
    authDomain: "amnius-connect.firebaseapp.com",
    projectId: "amnius-connect",
    storageBucket: "amnius-connect.appspot.com",
    messagingSenderId: "276009679670",
    appId: "1:276009679670:web:d14332ae75fc14dacf9197",
    measurementId: "G-KQHZFZQN7E",
  });

  useEffect(() => {
    if (loggedInUserId) {
      requestForNotificationToken(loggedInUserId);
    }
  }, [loggedInUserId]);

  const getNotificationTabsTotalCountHandler = () => {
    const response = getNotificationTabsTotalCount(token);
    response.then((res) => {
      if (res?.status) {
        dispatch(
          getRecentNotificationCountAction(res?.TotalUnreadNotificationCount)
        );
      }
    });
  };

  onMessage(messaging, (payload) => {
    toast(
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>{payload?.data?.title}</label>
        <label style={{ fontSize: "13px" }}>{payload?.data?.body}</label>
      </div>,
      { type: "info" }
    );
    getNotificationTabsTotalCountHandler();
  });

  const messageRef = firebase
    .firestore()
    .collection("chatroom")
    .orderBy("createdAt", "desc");

  messageRef.onSnapshot((querySnap) => {
    let unreadMessage = [];
    querySnap.docs?.map((docSanp) => {
      const data = docSanp.data();
      // function here returns the chatID of our chats
      for (let i in data?.chatID) {
        if (loggedInUserId?.toString() === data?.chatID[i]?.toString()) {
          if (data?.seen_data) {
            for (let i in data?.seen_data) {
              if (
                Object.values(data?.seen_data[i])?.toString() === "unseen" &&
                Object.keys(data?.seen_data[i])?.toString() ===
                  loggedInUserId?.toString()
              ) {
                unreadMessage.push(1);
              }
            }
            dispatch(newChatAlert(unreadMessage?.length));
          }
        }
      }
    });
  });

  return (
    <div className="App">
      <AppRoutes />
      <AlertPopup />
      <VerificationDialog />
      <ToastContainer
        position="top-right"
        autoClose={4000}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        theme="colored"
        transition={Slide}
      />
    </div>
  );
}

export default App;
