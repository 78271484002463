import { createAGroup, sendImageAPI } from "Config/API/action";
import {
  renderChatProfileAction,
  renderGroupDataAction,
} from "Redux/action/render.action";
import { useDispatch, useSelector } from "react-redux";

import GroupPopup from "../PureComponents/GroupPopup";
import React from "react";
import { toast } from "react-toastify";
import { updateGroup } from "Config/API/update";
import useFullPageLoader from "Hooks/useFullPageLoader";

const RenderGroupPopup = ({ setGroupPopup, groupData, conn_id }) => {
  const [loader, setLoading, resetLoading] = useFullPageLoader();
  const token = localStorage.getItem("token");
  const my_id = useSelector((state) => state.authenticationReducer?.id);
  const unique_id = useSelector((state) => state.renderChatProfileReducer);
  const dispatch = useDispatch();

  const createGroup = (docid, sorted_item, imageData, name) => {
    setLoading();
    const image_status = sendImageAPI(token, imageData, `chat/${docid}`, "");
    image_status
      .then((res) => {
        res?.json().then((data) => {
          if (data?.status) {
            if (data?.message?.includes("limit")) {
              resetLoading();
              return toast(data?.message, {
                type: "info",
              });
            }
            const group_creation_status = createAGroup(
              token,
              docid,
              name,
              my_id,
              sorted_item,
              conn_id,
              data.data,
              "regular",
              "",
              ""
            );
            group_creation_status.then((res) => {
              if (res?.status) {
                toast(res?.message, {
                  type: "success",
                });
                dispatch(renderGroupDataAction(res?.created));
                dispatch(renderChatProfileAction(res?.created?.unique_id));
              } else {
                toast(res?.message, {
                  type: "error",
                });
              }
            });
          } else {
            toast(data?.message, {
              type: "error",
            });
          }
        });
        resetLoading();
      })
      .catch((e) => {
        toast("Failed! Try again later", {
          type: "error",
        });
      });
  };

  const verify = (list_item, imageData, name) => {
    list_item = [...list_item, my_id, conn_id];
    const sorted_item = list_item.sort(function (a, b) {
      return a - b;
    });
    let docid = "";
    let i = 0;
    for (i; i < sorted_item.length - 1; i++) {
      docid = docid + sorted_item[i] + "-";
    }
    docid = docid + sorted_item[i];

    if (imageData?.toString() !== "") {
      // if image is available then this function will work
      createGroup(docid, sorted_item, imageData, name);
    } else {
      setLoading();
      const group_creation_status = createAGroup(
        token,
        docid,
        name,
        my_id,
        sorted_item,
        conn_id,
        "",
        "regular",
        "",
        ""
      );
      group_creation_status.then((res) => {
        if (res?.status) {
          toast(res?.message, {
            type: "success",
          });
          dispatch(renderChatProfileAction(res?.created?.unique_id));
          dispatch(renderGroupDataAction(res?.created));
        } else {
          toast(res?.message, {
            type: "error",
          });
        }
      });
      resetLoading();
    }
  };

  // updating group data
  const update = (list_item, remove_item, name, imageData) => {
    // if image has data
    if (imageData?.toString() !== "") {
      setLoading();
      const image_status = sendImageAPI(
        token,
        imageData,
        `chat/${unique_id}`,
        ""
      );
      image_status
        .then((res) => {
          res?.json().then((data) => {
            if (data?.status) {
              if (data?.message?.includes("limit")) {
                resetLoading();
                return toast(data?.message, {
                  type: "info",
                });
              }
              const update_group = updateGroup(
                token,
                unique_id,
                list_item,
                remove_item,
                name,
                data.data
              );
              update_group
                .then((res) => {
                  if (res?.status) {
                    toast(res?.message, {
                      type: "success",
                    });
                    resetLoading();
                    window.location.reload();
                  } else {
                    toast(res?.message, {
                      type: "error",
                    });
                  }
                })
                .catch((e) => {
                  toast("Failed! Try again later", {
                    type: "error",
                  });
                });
            } else {
              toast(data?.message, {
                type: "error",
              });
            }
          });
          // resetLoading();
        })
        .catch((e) => {
          toast("Failed! Try again later", {
            type: "error",
          });
        });
    } else {
      const update_group = updateGroup(
        token,
        unique_id,
        list_item,
        remove_item,
        name
      );
      update_group
        .then((res) => {
          if (res?.status) {
            toast(res?.message, {
              type: "success",
            });
            window.location.reload();
          } else {
            toast(res?.message, {
              type: "error",
            });
          }
        })
        .catch((e) => {
          toast("Failed! Try again later", {
            type: "error",
          });
        });
    }
  };

  return (
    <>
      <GroupPopup
        setGroupPopup={setGroupPopup}
        verify={verify}
        update={update}
        data={
          Object.keys(groupData).length?.toString() !== "0" ? groupData : {}
        }
      />
      {loader}
    </>
  );
};

export default RenderGroupPopup;
